import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ThemeProvider from "./components/Dashboard/theme";
import ScrollToTop from "./components/Dashboard/components/scroll-to-top";

import "./App.css";
import RegistrationForm from "./components/Registration/patronRegistration";
import LandingPage from "./components/Landing-Page/landingPage";
import MealHostRegistrationForm from "./components/Registration/mealHostRegistration";
import PatronHome from "./components/Home-Page/patronHome";
import { PatronRatings } from "./components/Ratings/patronRatings";
import { MealHostContact } from "./components/Contact-Information/mealHostContact";
import { MealHostSelect } from "./components/Selection/mealHostSelect";
import { PatronMealHistory } from "./components/Meal-History/patronMealHistory";
import { MealHostNotFound } from "./components/Selection/mealHostNotFound";
import { ChangePassword } from "./components/Password/changePassword";
import LoginUser from "./components/Login/login";
import { UpdatePatronRegistration } from "./components/Registration/updatePatronRegistration";
import ForgotPassword from "./components/Password/forgotPassword";
import ResetPassword from "./components/Password/resetPassword";
import { getAuthUser } from "./components/Authentication/authHelper";
import { MealHostLandingPage } from "./components/Landing-Page/mealHostLandingPage";
import { MealHostInstructions } from "./components/Instructions/mealHostInstructions";
import { MealHostRatings } from "./components/Ratings/mealHostRatings";
import { MealHostServedHistory } from "./components/Meal-History/mealHostServedHistory";
import { UpdateMealHostRegistration } from "./components/Registration/updateMealHostRegistration";
import { MealHostHome } from "./components/Home-Page/mealHostHome";
import { Toaster } from "react-hot-toast";
import {
  PrivateAdminRoute,
  PrivateRoute,
  RedirectRoute,
} from "./components/Authentication/privateRoute";
import {
  PatronRoute,
  MealHostRoute,
  AdminRoleRoute,
} from "./components/Authentication/roleRoute";
import { PaymentSuccess } from "./components/Payment/paymentSuccess";
import { PatronOrderDetails } from "./components/Payment/patronOrderDetails";
import Home from "./components/Landing-Page/home";
import { PaymentFailed } from "./components/Payment/paymentFailed";
import { Feedback } from "./components/Feedback/feedback";
import { PatronContact } from "./components/Contact-Information/patronContact";
import { OrderAccept } from "./components/Selection/orderAccept";
// import { SubscriptionScreen } from "./components/Payment/subscriptionScreen";
import SocketConnection from "./socket";
import { UpdateMeal } from "./components/Home-Page/updateMeal";
import { PendingOrder } from "./components/Selection/pendingOrder";
import { PatronPayment } from "./components/Payment/patronPayment";
import { MealHostPayment } from "./components/Payment/mealHostPayment";
import LoginPage from "./components/Dashboard/Login/LoginPage";
import PatronPage from "./components/Dashboard/Patron/PatronPage";
import DashboardAppPage from "./components/Dashboard/DashboardAppPage";
import MealhostPage from "./components/Dashboard/Mealhost/MealHostPage";
import WebHomePage from "./components/Landing-Page/WebHomePage";
import { MealHostStars, StarsEarned } from "./components/Ratings/starsEarned";
import { AcceptedMeals } from "./components/Meal-History/acceptedMeals";
import { Enquiry } from "./components/Landing-Page/enquiry";
import { CommunicationPage } from "./components/Dashboard/communicationPage";
import { MarketingPage } from "./components/Dashboard/marketingPage";
import { MealHostToday } from "./components/Meal-History/mealHostToday";
import { PatronCodeOfConduct } from "./components/CodeOfConduct/PatronCodeOfConduct";
import { MealhostCodeOfConduct } from "./components/CodeOfConduct/MealhostCodeOfConduct";

function App() {
  return (
    <>
      <Router>
        <SocketConnection />
        <Routes>
          {/* <Route
            path="/"
            element={
              // <RedirectRoute>
                // {/* <Home /> */}
          {/* <WebHomePage /> */}
          {/* </RedirectRoute> */}
          {/* />} */}
          <Route
            path="/enquiry"
            element={
              // <RedirectRoute>
              // {/* <Home /> */}
              <Enquiry />
              // </RedirectRoute>
            }
          />
          <Route
            path="/admin/dashboard"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <ScrollToTop />
                  <PrivateAdminRoute>
                    <AdminRoleRoute>
                      <DashboardAppPage />
                    </AdminRoleRoute>
                  </PrivateAdminRoute>
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/admin/dashboard/login"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <LoginPage />
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/admin/patrons"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <PrivateAdminRoute>
                    <AdminRoleRoute>
                      <PatronPage />
                    </AdminRoleRoute>
                  </PrivateAdminRoute>
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/admin/mealhosts"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <PrivateAdminRoute>
                    <AdminRoleRoute>
                      <MealhostPage />
                    </AdminRoleRoute>
                  </PrivateAdminRoute>
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/admin/communications/:user"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <PrivateAdminRoute>
                    <AdminRoleRoute>
                      <CommunicationPage />
                    </AdminRoleRoute>
                  </PrivateAdminRoute>
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/admin/marketing"
            element={
              <HelmetProvider>
                <ThemeProvider>
                  <PrivateAdminRoute>
                    <AdminRoleRoute>
                      <MarketingPage />
                    </AdminRoleRoute>
                  </PrivateAdminRoute>
                </ThemeProvider>
              </HelmetProvider>
            }
          />
          <Route
            path="/update-menu"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <UpdateMeal />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          {/* <Route
            path="/patron"
            element={
              // <PatronRoute>
              <LandingPage />
              // </PatronRoute>
            }
          /> */}
          {/* <Route
            path="/mealhost"
            element={
              // <MealHostRoute>
              <MealHostLandingPage />
              // </MealHostRoute>
            }
          /> */}
          {/* <Route path="/patron-registration" element={<RegistrationForm />} /> */}
          <Route path="/patron" element={<RegistrationForm />} />
          <Route
            // path="/mealhost-registration"
            path="/mealhost"
            element={<MealHostRegistrationForm />}
          />
          <Route
            path="/patron-code-of-Conduct"
            element={<PatronCodeOfConduct />}
          />
          <Route
            path="/mealhost-code-of-Conduct"
            element={<MealhostCodeOfConduct />}
          />
          <Route
            path="/patron-home"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <PatronHome />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/patron-ratings"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <PatronRatings />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            key="patron"
            path="/patron-stars"
            element={
              <PatronRoute>
                <StarsEarned />
              </PatronRoute>
            }
          />
          <Route
            path="/mealhost-contact"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <MealHostContact />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/select-mealhost"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <MealHostSelect />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/patron-meal-history"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <PatronMealHistory />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/patron-mealhost-today"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <MealHostToday />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/mealhost-not-found"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <MealHostNotFound />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/change-password"
            element={
              //<PrivateRoute>
              <ChangePassword />
              //<PrivateRoute>
            }
          />
          <Route path="/user-login" element={<LoginUser />} />
          <Route
            path="/update-patron-info"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <UpdatePatronRegistration />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route
            path="/mealhost-instructions"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <MealHostInstructions />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/mealhost-ratings"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <MealHostRatings />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            key="mealhost"
            path="/mealhost-stars"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <StarsEarned />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/mealhost-served-history"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <MealHostServedHistory />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/mealhost-accepted-meals"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <AcceptedMeals />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/update-mealhost-info"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <UpdateMealHostRegistration />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/mealhost-home"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <MealHostHome />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          {/* <Route path="/payment-success" element={<PaymentSuccess />} /> */}
          <Route
            path="/payment-success"
            element={<MealHostRegistrationForm />}
          />
          <Route path="/payment-failed" element={<PaymentFailed />} />
          <Route
            path="/patron-order-details"
            element={
              //<PrivateRoute>
              <PatronRoute>
                <PatronOrderDetails />
              </PatronRoute>
              //<PrivateRoute>
            }
          />
          {/* <Route path="/patron-payment"
            element={
              //<PrivateRoute>
                <PatronRoute>
                  <PatronPayment />
                </PatronRoute>
              //<PrivateRoute>} /> */}
          {/* <Route
            path="/mealhost/subscription"
            element={
              //<PrivateRoute>
                <MealHostRoute>
                  <SubscriptionScreen />
                </MealHostRoute>
              //<PrivateRoute>
            }
          /> */}
          <Route
            path="/feedback"
            element={
              //<PrivateRoute>
              <Feedback />
              //<PrivateRoute>
            }
          />
          <Route
            path="/patron-contact"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <PatronContact />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/order-accept-decline"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <OrderAccept />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          <Route
            path="/pending-order"
            element={
              //<PrivateRoute>
              <MealHostRoute>
                <PendingOrder />
              </MealHostRoute>
              //<PrivateRoute>
            }
          />
          {/* <Route path="/mealhost-payment" element={<MealHostPayment />} /> */}
        </Routes>
      </Router>
      <Toaster position="top-right" />
    </>
  );
}

export default App;
