// Helper function to convert Base64Url to Base64
const base64UrlToBase64 = (base64Url) => {
  // Replace '-' with '+', '_' with '/', and add padding if necessary
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  
  // Add padding characters if needed (Base64 strings should be a multiple of 4)
  while (base64.length % 4 !== 0) {
    base64 += '=';
  }
  
  return base64;
};

const deCodePayload = (token) => {
  const base64 = base64UrlToBase64(token);
  return JSON.parse(atob(base64));
};

export const getPayload = (token) => {
  const payload = token.split(".")[1]; // Extract the payload part of the token
  return deCodePayload(payload);
};


export const getAuthUser = () => {
  const token = localStorage.getItem("_token");
  if (token) {
    const payload = getPayload(token);
    return {
      _id: payload.userDetails._id,
      first_name: payload.userDetails.first_name,
      last_name: payload.userDetails.last_name,
      dob: payload.userDetails?.dob,
      registration_date: payload.userDetails.registration_date,
      address_line1: payload.userDetails.address_line1,
      address_line2: payload.userDetails.address_line2,
      city: payload.userDetails.city,
      country: payload.userDetails.country,
      country_code: payload.userDetails.country_code,
      state: payload.userDetails.state,
      zip_code: payload.userDetails.zip_code,
      phone: payload.userDetails.phone,
      email: payload.userDetails.email,
      card_details: payload.userDetails.card_details,
      preferences: payload.userDetails.preferences,
      allergies: payload.userDetails.allergies,
      membership_no: payload.userDetails.membership_no,
      ratings: payload.userDetails.ratings,
      num_of_reviews: payload.userDetails.num_of_reviews,
      reviews: payload.userDetails.reviews,
      role: payload.userDetails.role,
      profile: payload.userDetails.profile,
      account_no: payload.userDetails.account_no,
      routing_no: payload.userDetails.routing_no,
      bank_name: payload.userDetails.bank_name,
      bank_address: payload.userDetails.bank_address,
      account_holder_name: payload.userDetails.account_holder_name,
      ssn_last_4: payload.userDetails.ssn_last_4
      // password: payload.userDetails.password
    };
  }
};

const isValid = () => {
  const token = localStorage.getItem("_token");

  if (token) {
    const payload = getPayload(token);

    if (Date.now() >= payload?.exp * 1000) {
      return false;
    } else {
      return true;
    }
  } else {
    return false;
  }
};
export const isLoggedIn = () => {
  return isValid();
};
