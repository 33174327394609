import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
import axios from "axios";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
// sections
import { UserListHead, UserListToolbar } from "../Patron/UserListHead";
// mock
// import USERLIST from '../_mock/user';
import { toast } from "react-hot-toast";
import { mainServerAppUrl } from "../../../apis/mainApi";
import Header from "../layout/dashboard/header";
import Nav from "../layout/dashboard/nav";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
// import UserDetails from "./userDetails";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srno", label: "Sr. No.", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "day", label: "Day", alignRight: false },
  { id: "type", label: "Type", alignRight: false },
  { id: "allergies", label: "Allergies", alignRight: false },
  { id: "preferences", label: "Preferences", alignRight: false },
  { id: "price", label: "Price", alignRight: false },
  // { id: "delete", label: "Delete", alignRight: false },
];

// ----------------------------------------------------------------------
const USERLIST = [];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// function applySortFilter(array, comparator, query) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) return order;
//     return a[1] - b[1];
//   });
//   if (query) {
//     return filter(
//       array,
//       (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
//     );
//   }
//   return stabilizedThis.map((el) => el[0]);
// }

export default function MealDetails({ mealDetails, onClose }) {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

//   const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [meals,setMeals] = useState([]);

useEffect(()=>{
    setMeals(mealDetails);
},[])
//   const handleRequestSort = (event, property) => {
//     const isAsc = orderBy === property && order === "asc";
//     setOrder(isAsc ? "desc" : "asc");
//     setOrderBy(property);
//   };

//   const handleSelectAllClick = (event) => {
//     if (event.target.checked) {
//       const newSelecteds = USERLIST.map((n) => n.name);
//       setSelected(newSelecteds);
//       return;
//     }
//     setSelected([]);
//   };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  // const handleDelete = (_id, i) => {
  //   confirmAlert({
  //     message: "Do you want to DELETE this Meal?",
  //     buttons: [
  //       {
  //         label: "Yes",
  //         onClick: () => {
  //           axios
  //             .delete(mainServerAppUrl + `/admin/meals/delete/${_id}`)
  //             .then((result) => {
  //               toast.success(result.data.message, {
  //                 id: "deleted",
  //                 duration: 3000,
  //               });
  //               const updatedMeals = meals.filter((meal) => meal._id !== _id);
  //               setMeals(updatedMeals);
  //             })
  //             .catch((error) => {
  //               console.log(error.response);
  //               toast.error(error.response.data.message, {
  //                 id: "cancel",
  //                 duration: 3000,
  //               });
  //             });
  //         },
  //       },
  //       {
  //         label: "No",
  //       },
  //     ],
  //   });
  // };

  //   const handleFilterByName = (event) => {
  //     setPage(0);
  //     setFilterName(event.target.value);
  //   };

  //   const emptyRows =
  //     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

//   const filteredUsers = applySortFilter(
//     USERLIST,
//     getComparator(order, orderBy),
//     filterName
//   );

//   const isNotFound = !filteredUsers.length && !!filterName;

//   const APP_BAR_MOBILE = 64;
//   const APP_BAR_DESKTOP = 92;

  return (
    <>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <h5>Menu:</h5>

          <MenuItem sx={{ color: "black" }} onClick={onClose}>
            <Tooltip title="Close">
              <Iconify icon={"eva:close-fill"} sx={{ mr: 2 }} />
            </Tooltip>
          </MenuItem>
        </Stack>
        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={meals.length}
                numSelected={selected.length}
                // onRequestSort={handleRequestSort}
                // onSelectAllClick={handleSelectAllClick}
              />
              <TableBody>
                {meals
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((meal, ind) => (
                    <TableRow key={ind} style={{boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px"}} >
                      <TableCell align="left">
                            {ind + 1}
                          </TableCell>
                      <TableCell align="left">
                        <Box display="flex" alignItems="center">
                            <Tooltip title="click to enlarge image">
                          <img width="50px"
                            src={
                              `${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                              meal?.imageUrl
                            }
                            alt={meal?.imageUrl}
                            onClick={()=>{window.open(`${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                            meal?.imageUrl)}}
                            style={{cursor:"pointer"}}
                          />
                          </Tooltip>
                        </Box>
                      </TableCell>
                      <TableCell align="left">
                        {meal.meal_name}
                      </TableCell>
                      <TableCell align="left">{meal.description}</TableCell>
                      <TableCell align="left">{meal.meal_day}</TableCell>
                      <TableCell align="left">{meal.meal_type}</TableCell>
                      <TableCell align="left">{meal.allergies.map((alg,i)=>(
                            <li index={i}>{alg}</li>
                      ))}</TableCell>
                      <TableCell align="left">{meal.preferences.map((pref,i)=>(
                            <li index={i}>{pref}</li>
                      ))}</TableCell>
                      <TableCell align="left"> $ {meal.price}</TableCell>
                      {/* <TableCell align="right">
                            <MenuItem
                              sx={{ color: "error.main" }}
                              onClick={() =>
                                handleDelete(meal._id, ind)
                              }
                            ><Tooltip title="Delete">
                              <Iconify
                                icon={"eva:trash-2-outline"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell> */}
                    </TableRow>
                  ))}
              </TableBody>

              {/* {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <Paper
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        <Typography variant="h6" paragraph>
                          Not found
                        </Typography>

                        <Typography variant="body2">
                          No results found for &nbsp;
                          <strong>&quot;{filterName}&quot;</strong>.
                          <br /> Try checking for typos or using complete words.
                        </Typography>
                      </Paper>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )} */}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={meals.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              ".MuiTablePagination-displayedRows": {
                marginBottom: "0px",
              },
              ".MuiTablePagination-selectLabel": {
                marginBottom: "2px",
              },
              ".MuiTablePagination-select": {
                marginBottom: "-1px",
              },
            }}
          />
        </Card>
      </Container>

    </>
  );
}
