import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { mainServerAppUrl } from "../../apis/mainApi";
import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { getAuthUser } from "../Authentication/authHelper";
import useGeoLocation from "../../hooks/useGeoLocation";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export default function PatronHome() {
  const [mealType, setMealType] = useState("");
  const [guestNo, setGuestNo] = useState(1);
  const [preferences, setPreference] = useState([]);
  const [allergies, setAllergies] = useState([]);
  const location = useGeoLocation();

  const user = getAuthUser();

  const navigate = useNavigate();
  console.log(location);


  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  const handlePreference = (value) => {
    const preference = preferences.includes(value);
    if (preference === false) {
      preferences.push(value);
    } else {
      const ind = preferences.indexOf(value);
      preferences.splice(ind, 1);
    }
  };

  const handleAllergies = (value) => {
    const allergie = allergies.includes(value);
    if (allergie === false) {
      allergies.push(value);
    } else {
      const ind = allergies.indexOf(value);
      allergies.splice(ind, 1);
    }
  };

  const searchMealHost = () => {    
    const data = {
      meal_type: mealType,
      order_quantity: guestNo,
      preferences: guestNo > 1 ? preferences : user?.preferences,
      allergies: guestNo > 1 ? allergies : user?.allergies,
      location: location.coordinates,
    };
    if (mealType && data.preferences.length > 0 && location.coordinates !== undefined) {
      axios
        .post(mainServerAppUrl + "/user/patron/search-meal-host", data)
        .then((result) => {
          // console.log(result);
          navigate("/select-mealhost", { state: { ...result.data, location: location.coordinates } });
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 400) {
            toast.error(error.response.data.result)
            navigate("/mealhost-not-found");
          }
        });
    } else if (data.preferences.length > 0 && !mealType) {
      toast.error("Please Select Meal Type", {
        id: "failMealType",
        duration: 3000,
      });
      guestNo > 1 && window.scrollTo(0, 450);
    } else if (mealType && data.preferences.length <= 0) {
      toast.error("Please Select at least one Preference", {
        id: "failPreference",
        duration: 3000,
      });
      window.scrollTo(0, 450);
    } else if (location.coordinates === undefined) {
      toast.error("please activate location", {
        id: "faillocation",
        duration: 3000,
      });
      window.scrollTo(0, 450);
    }else {
      toast.error("Please Select Meal Type and Preferences", {
        id: "fail",
        duration: 3000,
      });
      window.scrollTo(0, 450);
    }
  };

  return (
    <div className="max-resolution">
       <div className="home-section-1 position-relative">
          <span className="text_msg">PATRON MY Homey-FOODS</span>
        </div>
    <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          <SideMenu />
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="meal_section justify-content-center">
              <button
                className="select_meal_time"
                onClick={(e) => {
                  e.preventDefault();
                  setMealType("Breakfast");
                }}
                style={mealType === "Breakfast" ? { background: "blue" } : {}}
                disabled={new Date().getHours() > 9 ? true : false}
              >
                <div className="meal_time">
                  <span className="meal_time_1">Breakfast</span>
                  <span className="meal_time_2">8- 10 AM</span>
                </div>
              </button>
              <button
                className="select_meal_time"
                onClick={(e) => {
                  e.preventDefault();
                  setMealType("Lunch");
                }}
                style={mealType === "Lunch" ? { background: "blue" } : {}}
                disabled={new Date().getHours() > 14 ? true : false}
              >
                <div className="meal_time">
                  <span className="meal_time_1">Lunch</span>
                  <span className="meal_time_2">1- 3 PM</span>
                </div>
              </button>
              <button
                className="select_meal_time"
                onClick={(e) => {
                  e.preventDefault();
                  setMealType("Dinner");
                }}
                disabled={new Date().getHours() > 20 ? true : false}
                style={mealType === "Dinner" ? { background: "blue" } : {}}
              >
                <div className="meal_time">
                  <span className="meal_time_1">Dinner</span>
                  <span className="meal_time_2">7- 9 PM</span>
                </div>
              </button>
            </div>
            <div className="row">
              <div className="col-12">
                <input
                  type="date"
                  name="date"
                  placeholder="Date"
                  value={moment(new Date()).format("YYYY-MM-DD")}
                  className="w-100 form-control pt-0"
                  disabled
                />
              </div>
              <div className="col-12 position-relative">
                <input
                  type="text"
                  name="guest"
                  placeholder="Add Guest"
                  value={guestNo}
                  className="w-100 form-control border_none"
                  readOnly
                />
                <button
                  className="more_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    setGuestNo(guestNo + 1);
                  }}
                ></button>
                <button
                  className="minus_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    if (guestNo > 1) {
                      setGuestNo(guestNo - 1);
                    }
                  }}
                ></button>
              </div>
              {guestNo > 1 && (
                <div className="row checkbox_group">
                  <span className="form_heading">Preferences</span>
                  <div className="col-12 p-0">
                    <div className="d-flex flex-wrap">
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="preference_1"
                          name="preferences[]"
                          value="Veg"
                          onChange={(e) => handlePreference(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="preference_1"
                        >
                          Veg
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="preference_2"
                          name="preferences[]"
                          value="Non-Veg"
                          onChange={(e) => handlePreference(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="preference_2"
                        >
                          Non-Veg
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="preference_3"
                          name="preferences[]"
                          value="Vegan"
                          onChange={(e) => handlePreference(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="preference_3"
                        >
                          Vegan
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="preference_4"
                          name="preferences[]"
                          value="Halal"
                          onChange={(e) => handlePreference(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="preference_4"
                        >
                          Halal
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="preference_5"
                          name="preferences[]"
                          value="Kosher"
                          onChange={(e) => handlePreference(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="preference_5"
                        >
                          Kosher
                        </label>
                      </div>
                    </div>
                  </div>

                  <span className="form_heading">Allergies</span>
                  <div className="col-12 p-0">
                    <div className="d-flex flex-wrap">
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allergies_1"
                          name="allergies[]"
                          value="Eggs"
                          onChange={(e) => handleAllergies(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allergies_1"
                        >
                          Eggs
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allergies_2"
                          name="allergies[]"
                          value="Peanuts"
                          onChange={(e) => handleAllergies(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allergies_2"
                        >
                          Peanuts
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allergies_3"
                          name="allergies[]"
                          value="Shell Fish"
                          onChange={(e) => handleAllergies(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allergies_3"
                        >
                          Shell Fish
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allergies_4"
                          name="allergies[]"
                          value="Soy"
                          onChange={(e) => handleAllergies(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allergies_4"
                        >
                          Soy
                        </label>
                      </div>
                      <div className="col-6 p-0">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="allergies_5"
                          name="allergies[]"
                          value="Gluten"
                          onChange={(e) => handleAllergies(e.target.value)}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="allergies_5"
                        >
                          Gluten
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>

            <div className="col-12 p-0">
              <input
                type="submit"
                value="SEARCH"
                className="btn w-100"
                onClick={(e) => {
                  e.preventDefault();
                  searchMealHost();
                }}
              />
            </div>
          </form>
        </div>
      </main>
      <Footer/>
    </div>
  );
}
