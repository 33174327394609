import { Helmet } from "react-helmet-async";
import { filter } from "lodash";
// import { sentenceCase } from 'change-case';
import { useEffect, useState } from "react";
import axios from "axios";
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
// components
import Label from "../components/label";
import Iconify from "../components/iconify";
// sections
import { UserListHead, UserListToolbar } from "../Patron/UserListHead";
// mock
// import USERLIST from '../_mock/user';
import { toast } from "react-hot-toast";
import { mainServerAppUrl } from "../../../apis/mainApi";
import Header from "../layout/dashboard/header";
import Nav from "../layout/dashboard/nav";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css'
// import UserDetails from "./userDetails";
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "srno", label: "Sr No.", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "patron", label: "Patron", alignRight: false },
  { id: "mealtype", label: "Type", alignRight: false },
  { id: "mealname", label: "Meal Name", alignRight: false },
  { id: "qty", label: "Qty", alignRight: false },
  { id: "amount", label: "Amount", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "invoice", label: "invoice", alignRight: false },
];

// ----------------------------------------------------------------------
const USERLIST = [];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function OrdersByMealhost({onClose,orders}) {

  
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(5);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

//   const handleFilterByName = (event) => {
//     setPage(0);
//     setFilterName(event.target.value);
//   };


//   const emptyRows =
//     page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orders.length) : 0;

  const filteredUsers = applySortFilter(
    USERLIST,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredUsers.length && !!filterName;

  const APP_BAR_MOBILE = 64;
  const APP_BAR_DESKTOP = 92;

//   const StyledRoot = styled("div")({
//     display: "flex",
//     minHeight: "100%",
//     overflow: "hidden",
//   });

//   const Main = styled("div")(({ theme }) => ({
//     flexGrow: 1,
//     overflow: "auto",
//     minHeight: "100%",
//     paddingTop: APP_BAR_MOBILE + 24,
//     paddingBottom: theme.spacing(10),
//     [theme.breakpoints.up("lg")]: {
//       paddingTop: APP_BAR_DESKTOP + 24,
//       paddingLeft: theme.spacing(2),
//       paddingRight: theme.spacing(2),
//     },
//   }));

  return (
    <>
      {/* <StyledRoot> */}



          <Container>
          <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <h5>Order Details:</h5>

        <MenuItem sx={{ color: "black" }} onClick={onClose}>
        <Tooltip title="Close">
          <Iconify icon={"eva:close-fill"} sx={{ mr: 2 }} />
          </Tooltip>
        </MenuItem>
      </Stack>
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={orders.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {orders
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((order, ind) => 
                        (<TableRow key={ind}>
                          <TableCell align="left">
                            {ind + 1}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(order.ordered_On).getDate() + "/"
                        + (new Date(order.ordered_On).getMonth()+1)  + "/" 
                        + new Date(order.ordered_On).getFullYear() + " - "  
                        + new Date(order.ordered_On).getHours() + ":"  
                        + new Date(order.ordered_On).getMinutes()}
                          </TableCell>
                          <TableCell align="left">
                            {order.patron+ " ("+order.patron_id+")"}
                          </TableCell>
                          <TableCell align="left">{order.meal_type}</TableCell>
                          <TableCell align="left">{order.meal_name}</TableCell>
                          <TableCell align="left">{order.order_quantity}</TableCell>
                          <TableCell align="left">{order.price}</TableCell>
                          {
                             order.order_status === "delivered" || order.order_status === "confirmed" ?
                             (
                                <TableCell align="left" style={{color:"green"}}>{order.order_status === "delivered" ? "Served" : order.order_status }</TableCell>
                             ):
                             order.order_status === "processing" ? 
                             (
                                <TableCell align="left" style={{color:"orange"}}>{order.order_status}</TableCell>
                             ):
                                <TableCell align="left" style={{color:"red"}}>{order.order_status}</TableCell>
                          }
                          {
                            order.order_status === "delivered" || order.order_status === "confirmed" ?
                            (<TableCell align="right">
                            <MenuItem
                              sx={{ color: "green" }}
                              onClick={()=>{
                                if (order && order.payment && order.payment.invoiceURL) {
                                    window.open(order.payment.invoiceURL);
                                  } else {
                                    toast.error("Invoice URL not found");
                                  }
                            }}
                            >
                              <Tooltip title="Link to Invoice">
                              <Iconify
                                icon={"eva:file-text-outline"}
                                sx={{ mr: 2 }}
                              />
                                </Tooltip>
                            </MenuItem>
                          </TableCell>):(
                             <TableCell align="right">
                             <MenuItem>
                               <Iconify
                                 icon={"carbon:not-available"}
                                 sx={{ mr: 2 }}
                               />
                             </MenuItem>
                           </TableCell>
                          )
                          }
                      
                        </TableRow>
                      ))}
                  </TableBody>

                  {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={orders.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{
                  ".MuiTablePagination-displayedRows": {
                    marginBottom: "0px",
                  },
                  ".MuiTablePagination-selectLabel": {
                    marginBottom: "2px",
                  },
                  ".MuiTablePagination-select": {
                    marginBottom: "-1px",
                  },
                }}
              />
            </Card>
          </Container>

      {/* </StyledRoot> */}
    </>
  );
}
