import { Helmet } from "react-helmet-async";
// @mui
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
// hooks
import useResponsive from "../hooks/useResponsive";
// sections
import { LoginForm } from "./LoginForm";
import { ThcmNavbar } from "../../Landing-Page/thcmNavbar";
import bgImg from "../../../assets/images/flatnoback.jpg"
import { ThcmFooter } from "../../Landing-Page/thcmFooter";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  background: `linear-gradient(rgba(5, 25, 1, 0.8), rgba(5, 6, 20, 0.8)), url(${bgImg})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundRepeat: "no-repeat",

}));

const StyledSection = styled("div")(({ theme }) => ({
  width: "100%",
  maxWidth: 480,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  boxShadow: theme.customShadows.card,
  backgroundColor: theme.palette.background.default,
  
}));

const StyledContent = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  // minHeight: "80vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
  
}));

// ----------------------------------------------------------------------

export default function LoginPage() {

  return (
    <>
      <Helmet>
        {/* <title> Admin Login </title> */}
      </Helmet>
      <ThcmNavbar />

      <StyledRoot>
        
        <Container maxWidth="sm" sx={{ height:"calc(100vh - 137px)" }}>
        <Typography textAlign="center" color="whitesmoke" variant="h3" marginTop="80px"  marginBottom="-50px">
            Admin Login
          </Typography>
          <StyledContent>
         
            <LoginForm />
          </StyledContent>
        </Container>
      </StyledRoot>
      <ThcmFooter/>
    </>
  );
}



// const StyledRoot = styled("div")(({ theme }) => ({
//   [theme.breakpoints.up("md")]: {
//     display: "flex",
//   },
// }));

// const StyledSection = styled("div")(({ theme }) => ({
//   width: "100%",
//   maxWidth: 480,
//   display: "flex",
//   flexDirection: "column",
//   justifyContent: "center",
//   boxShadow: theme.customShadows.card,
//   backgroundColor: theme.palette.background.default,
// }));

// const StyledContent = styled("div")(({ theme }) => ({
//   maxWidth: 480,
//   margin: "auto",
//   minHeight: "70vh",
//   display: "flex",
//   justifyContent: "center",
//   flexDirection: "column",
//   padding: theme.spacing(12, 0),
// }));

// // ----------------------------------------------------------------------

// export default function LoginPage() {
//   const mdUp = useResponsive("up", "md");

//   return (
//     <>
//       <ThcmNavbar />

//       <Helmet>
//         <title> Login </title>
//       </Helmet>
//       <StyledRoot>
//         {mdUp && (
//           <StyledSection>
//             <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
//               Hi, Welcome Back
//             </Typography>
//             <img
//               src={require("../../../assets/illustrations/illustration_login.png")}
//               alt="login"
//             />
//           </StyledSection>
//         )}

//         <Container maxWidth="sm">
//           <StyledContent>
//             <LoginForm />
//           </StyledContent>
//         </Container>
//       </StyledRoot>
//     </>
//   );
// }