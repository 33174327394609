import React, { useState, useEffect } from "react";
import { mainServerAppUrl } from "../../apis/mainApi";
import axios from "axios";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const PatronContact = () => {
  const [patronContact, setPatronContact] = useState([]);
  const location = useLocation();
  // console.log(location.state,"<---------------")

  const getPatronDetails = () => {
    axios
      .post(mainServerAppUrl + "/user/meal-host/patron-info",{membership_no:location.state.patron_id})
      .then((result) => {
        setPatronContact(result?.data?.patron);
        // console.log("patroninforesult",result?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPatronDetails();
  }, []);
  

  return (
    <div className="max-resolution">
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <h2>MEAL HOST YOUR PATRON INFORMATION</h2>
        </div>
      </section>
      <Header />
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="custom-form centered pb-4">
            <form>
              <div className="row">
                <div className="col-12">
                  <div className="patrons-details">
                    <ul className="list-unstyled">
                      <li>{moment(new Date()).format("DD-MM-YYYY")}</li>
                      <li>
                        {patronContact?.first_name +
                          " " +
                          patronContact?.last_name}
                      </li>
                      <li>
                        {patronContact?.address_line1 +
                          "," +
                          patronContact?.address_line2 +
                          "," +
                          patronContact?.city +
                          "," +
                          patronContact?.state +
                          "-" +
                          patronContact?.zip_code}
                      </li>
                      <li>
                        {"+" +
                          patronContact?.country_code +
                          "-" +
                          patronContact?.phone}
                      </li>
                      <li>{patronContact?.email}</li>
                      <li>Patron Profile: {patronContact?.profile}</li>
                      {/*need to discuss how to show ratings <li>{patronContact?.ratings}</li> */}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {/* <input
                    type="submit"
                    value="MEAL SERVED"
                    className="btn_form w-100"
                    onClick={(e) => {
                      e.preventDefault();
                      // orderDelivered(location.state._id);
                    }}
                  /> */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
