import React, { useEffect, useState } from "react";
import { SideMenu } from "../Side-Menu/sideMenu";
// import { socket } from "../../socket";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { Footer } from "../Footer/footer";
import { Header } from "../Header/header";
import { Rating } from "react-simple-star-rating";

export const OrderAccept = () => {
  const location = useLocation();
  let [allOrders, setAllOrders] = useState(location.state);

  const navigate = useNavigate();

  useEffect(() => {
    const storedOrders = JSON.parse(localStorage.getItem("orders")) || [];
    setAllOrders(storedOrders);
    //   console.log("storedorders",storedOrders)
    // setAllOrders(location.state)
  }, [location.state]);
  // let allOrders = JSON.parse(localStorage.getItem('orders')) || []

  const acceptDecline = (id, order, action) => {
    axios
      .post(mainServerAppUrl + `/orders/${action}/${id}`)
      .then((result) => {
        if (action === "accept") {
          payment(id,order);
        }
        allOrders = allOrders.filter((item) => item._id !== id);
        localStorage.setItem("orders", JSON.stringify(allOrders));
        setAllOrders(allOrders);
        action === "accept"
          ? toast.success(
              `Order Accepted\nOrder Name: ${result.data.meal_name}\nOrder Type: ${result.data.meal_type}\nQty:${result.data.order_quantity}`,
              { id: "order confirmation", duration: 10000 }
            )
          : toast.error(
              `Order Declined\nOrder id: ${id}\nOrder Name: ${result.data.meal_name}\nVisit "Orders History" menu to view order details`,
              { id: "order confirmation", duration: 10000 }
            );
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          toast.error("Some error occured", {
            id: "failure400",
            duration: 3000,
          });
        } else {
          toast.error(error?.response?.data.message, {
            id: "failure",
            duration: 3000,
          });
        }
      });
  };

  const payment = (orderId,order) => {
    axios
      .post(mainServerAppUrl + "/patron/payment/invoice", { orderId })
      .then((result) => {
        toast.dismiss("order confirmation");
        toast.success(result.data.message, { id: "invoice", duration: 3000 });
        navigate("/patron-contact", { state: order });
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss("order confirmation");
        toast.error(error.response.data.message, {
          id: "error",
          duration: 3000,
        });
      });
  };

  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  // let mealDay = weekday[new Date(location?.state?.ordered_On)?.getDay()];

  return (
    <div className="max-resolution">
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <h2>MEAL HOST ACCEPT OR DECLINE TO HOST</h2>
        </div>
      </section>
      <Header />
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      {allOrders &&
        allOrders.map((order, i) => (
          <main key={i}>
            <div className="container">
              <div className="custom-form centered mb-4">
                <form action="">
                  <div className="green_box_history meal-order mt-4">
                    <div className="row py-3">
                      <div className="col-12 text-center">
                        <p>{order.patron}</p>
                        <Rating
                          initialValue={order.ratings}
                          readonly
                          allowFraction
                          size={23}
                        />
                        <span style={{ fontSize: "15px" }}>
                          {"(" + order.num_of_reviews + ")"}
                        </span>
                      </div>
                    </div>
                    <div className="row py-3">
                      {/* {console.log(order)} */}
                      <div className="col-3">
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                            order?.imageUrl
                          }
                          alt={order?.imageUrl ? order?.imageUrl : "no image"}
                        />
                      </div>
                      <div className="col-6">
                        <p>{weekday[new Date(order.ordered_On).getDay()]}</p>
                        <small>{order?.meal_type}</small>
                        <br />
                        <small>{order?.meal_name} </small>
                        <br />
                        <small style={{ color: "black" }}>Quantity :</small>
                      </div>
                      <div className="col-3">
                        <p>${order?.price}</p>
                        <br />
                        <br />
                        <p style={{ fontSize: "15px", fontWeight: "bold" }}>
                          {order?.order_quantity}
                        </p>
                      </div>
                    </div>
                    <div className="row py-3">
                      <div className="col-9">
                        <p>Total</p>
                      </div>
                      <div className="col-3">
                        <p>${order?.price * order?.order_quantity}</p>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-6">
                      <input
                        type="submit"
                        value="ACCEPT"
                        className="btn_form w-100"
                        onClick={(e) => {
                          e.preventDefault();
                          acceptDecline(order._id,order, "accept");
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="submit"
                        value="DECLINE"
                        className="btn_form bg-pink w-100"
                        onClick={(e) => {
                          e.preventDefault();
                          acceptDecline(order._id,order, "decline");
                        }}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>
        ))}

      <Footer />
    </div>
  );
};
