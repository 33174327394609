import React from "react";
import { Container, MenuItem, Stack, Tooltip } from "@mui/material";
import Iconify from "../components/iconify/Iconify";
// import Typography from "../theme/overrides/Typography";

export default function UserDetails({ user, onClose, handleDelete }) {
  // console.log(user);
  return (
    <Container>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        mb={5}
      >
        <h5>User Details:</h5>

        <MenuItem sx={{ color: "black" }} onClick={onClose}>
        <Tooltip title="Close">
          <Iconify icon={"eva:close-fill"} sx={{ mr: 2 }} />
          </Tooltip>
        </MenuItem>
      </Stack>
      <p>Membership Number: {user.membership_no}</p>
      <p>Name: {user.first_name + " " + user.last_name}</p>
      <p>Email: {user.email}</p>
      <p>Address Line1: {user.address_line1}</p>
      <p>Address Line2: {user.address_line2}</p>
      <p>City: {user.city}</p>
      <p>Zip Code: {user.zip_code}</p>
      <p>State: {user.state}</p>
      <p>Country: {user.country + " (+" + user.country_code + ")"}</p>
      <p>Phone Number: {user.phone}</p>
      {user.card_details.map((card, i) => (
        <p key={i}>
          Card Number: {card.card_number} ( {card.card_type})
        </p>
      ))}
      {/* <p>membership_fee: {user.membership_fee}</p> */}
      <p>Preferences:
      {user.preferences.map((pref,i)=>(
        <span key={i}>
        {" "+pref + ", "}
      </span>
      ))}
       </p>
       <p>Allergies:
      {user.allergies.map((alg,i)=>(
        <span key={i}>
        {" "+alg + ", "}
      </span>
      ))}
       </p>
      {/* <p>customer_id: {user.customer_id}</p> */}
      <p>Ratings: {user.ratings}</p>
      <p>Number Of Reviews: {user.num_of_reviews}</p>
      <p>Profile: {user.profile}</p>
      {/* <button style={{ border: "none", background: "inherit" }}>
        <MenuItem
          sx={{ color: "error.main" }}
          onClick={() => handleDelete(user.membership_no)}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete User
        </MenuItem>
      </button> */}
    </Container>
  );
}
