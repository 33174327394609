import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { SideMenu } from "../Side-Menu/sideMenu";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const PatronCodeOfConduct = () => {
  // const [orderConfirmed, setOrderConfirmed] = useState("");

  const navigate = useNavigate();
  // const { state } = useLocation();
  // let checkoutUrl = "";
  // let order = {};
  // if (state) {
  //   checkoutUrl = state.checkoutUrl;
  //   order = state.order;
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
      <div className="home-section-1 position-relative">
        <span className="text_msg">Code of Conduct for the Patrons</span>
      </div>
      <Header />
      <main>
        <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div
          className="custom-form centered pb-4"
          style={{ paddingTop: "1rem" }}
        >
          <div style={{ fontSize: "16px", minHeight: "140px" }}>
            <ul className="px-2">
              <li>
                {" "}
                <b> Respectful Communication:</b> Treat all Meal Hosts and Homey
                Foods staff with respect. Use polite language and be patient in
                communications.{" "}
              </li>
              <li>
                {" "}
                <b> Honesty:</b> Provide accurate information regarding dietary
                restrictions, allergies, and preferences.{" "}
              </li>
              <li>
                {" "}
                <b> No Tipping or any Solicitations</b>
              </li>
              <li>
                {" "}
                <b> Feedback:</b> Offer constructive feedback to help Meal Hosts
                to improve their services.
              </li>
              <li>
                {" "}
                <b>Courtesy:</b> Be considerate of ' time by ordering and
                canceling meals within the given timeframe.{" "}
              </li>
              <li>
                {" "}
                <b> Respect for Property:</b> Treat the Mal Host's property with
                care and respect when receiving meals.{" "}
              </li>
              <li>
                {" "}
                <b> Professionalism:</b> Address any issues or complaints
                through the proper channels provided by Homey Foods, maintaining
                a professional demeanor.
              </li>
              <li>
                {" "}
                <b> Integrity:</b> Uphold honesty and integrity in all
                interactions.{" "}
              </li>
              <li>
                {" "}
                <b> Inclusivity:</b>Respect the diverse backgrounds and
                preferences of all patrons and meal providers.{" "}
              </li>
              <li>
                {" "}
                <b> Accountability:</b> Take responsibility for actions and
                decisions, striving to resolve issues amicably and fairly.{" "}
              </li>
              <li>
                {" "}
                <b> Safety:</b> Prioritize the safety and well-being of all
                parties{" "}
              </li>
              <li>
                {" "}
                <b> Continuous Improvement:</b> Commit to continuous improvement
                in service quality and customer satisfaction.
              </li>
              <li>
                {" "}
                <b> Respect for Privacy:</b> Protect Meal Hosts' personal
                information and do not share it without consent.
              </li>
              <li>
                {" "}
                <b> By adhering to the Code of Conduct:</b> contribute to a
                positive, respectful, and high-quality experience with Homey
                Foods.
              </li>
            </ul>
          </div>
          <div className="row">
            <div className="col-12 p-2" style={{ marginTop: "5rem" }}>
              <button
                className="btn w-100"
                type="button"
                onClick={() => navigate("/patron-home")}
              >
                GO TO HOME
              </button>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
