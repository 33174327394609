import React, { useEffect } from "react";
import { getAuthUser } from "../Authentication/authHelper";
// import moment from "moment";
import { useState } from "react";
import { mainServerAppUrl } from "../../apis/mainApi";
import axios from "axios";
// import { useLocation, useNavigate } from "react-router-dom";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import { Rating } from "react-simple-star-rating";
import { Typography } from "@mui/material";

export const StarsEarned = () => {

  let userId = getAuthUser()?.email
  const [user, setUser] = useState();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

 useEffect(() => {
    axios
      .get(mainServerAppUrl + `/user/${userId}`)
      .then((result) => {
        setUser(result.data);
      })
      .catch((error) => {
        toast.error(error.response.data.message, {
          id: "error",
          duration: 3000,
        });
        console.log(error);
      });
  }, [userId]);
  // console.log(user?.details[0].reviews)


  let reviews = user?.details[0].reviews;

  // console.log(reviews)

  const totalRatings = (reviews?.length * 5)
  const acquiredRatings = reviews?.map((item) => item.rating).reduce((sum, rating) => sum + rating, 0);

 return getAuthUser()?.role === "MealHost" ? 
   (
    <div className="max-resolution">
       <section className="title_box">
          <h2>MY EARNED STARS</h2>
      </section>
      <Header/>
      <section className="title_box">
        <div className="container position-relative">
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="custom-form centered mb-4">
            {/* <h3 style={{textAlign:"center",color:"white", fontWeight:"bold"}}>{acquiredRatings} out of {totalRatings} stars</h3> */}
            <div style={{alignItems:"center",justifyContent:"center",display:"flex",marginBottom:"10px"}}>
            <Rating
                          initialValue={getAuthUser()?.ratings}
                          readonly
                          allowFraction
                          size={60}
                          title={getAuthUser()?.ratings} // Tooltip text
                        />
                        </div>
                        <Typography style={{ fontSize: "20px",textAlign:"center" }}>
                          {getAuthUser()?.ratings +" out of 5 stars rated by "+ getAuthUser()?.num_of_reviews }
                        
                        {getAuthUser().role === "MealHost" ? (
                          <span> Patrons</span>
                        ) :
                        getAuthUser().role === "Patron" ? (
                          <span> Mealhosts</span>
                        ):(
                          <span> Users</span>
                        )
                        }
                        </Typography>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  ) : (
    <div className="max-resolution">
              <div className="home-section-1 position-relative">
          <span className="text_msg">MY STARS EARNED</span>
        </div>
      <Header/>
      <main>
        <div className="home-section-1 position-relative home-section-2">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
        <div style={{alignItems:"center",justifyContent:"center",display:"flex",marginBottom:"10px"}}>
            <Rating
                          initialValue={getAuthUser()?.ratings}
                          readonly
                          allowFraction
                          size={60}
                          title={getAuthUser()?.ratings} // Tooltip text
                        />
                        </div>
                        <Typography style={{ fontSize: "20px",textAlign:"center" }}>
                          {getAuthUser()?.ratings +" out of 5 stars rated by "+ getAuthUser()?.num_of_reviews }
                        
                        {getAuthUser().role === "MealHost" ? (
                          <span> Patrons</span>
                        ) :
                        getAuthUser().role === "Patron" ? (
                          <span> Mealhosts</span>
                        ):(
                          <span> Users</span>
                        )
                        }
                        </Typography>
          </div>
        </main>
      <Footer/>
    </div>
  );
};
