import React, { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom";


export const ThcmNavbar = () => {
    
    const [toggle, setToggle] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  
    const [isToggleOpen, setIsToggleOpen] = useState(false);
  const navigate = useNavigate();

    const toggleDropdown = () => {
      setIsDropdownOpen(!isDropdownOpen);
      setIsDropdownOpen1(false);
    };
    const toggleDropdown1 = () => {
      setIsDropdownOpen1(!isDropdownOpen1);
      setIsDropdownOpen(false)
    };
  
    const toggler = () => {
      setIsToggleOpen(!isToggleOpen);
    };
  
    const dropdownStyle = {
      whiteSpace: "normal",
      // wordWrap: "break-word",
      width: "380px",
    }
    
    return (
        <>
        <>
          <link
            rel="shortcut icon"
            type="image/x-icon"
            href="images/favicon.ico"
          />
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link
            href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&amp;display=swap"
            rel="stylesheet"
          />
          <link rel="stylesheet" href="css/style.css" />
          <link
            href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
            rel="stylesheet"
            integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
            crossOrigin="anonymous"
          />
          <link
            rel="stylesheet"
            href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css"
            integrity="sha512-5A8nwdMOWrSz20fDsjczgUidUBR8liPYU+WymTZP1lmY9G6Oc7HlZv156XqnsgNUzTyMefFTcsFH/tnJE/+xBg=="
            crossOrigin="anonymous"
            referrerPolicy="no-referrer"
          />
        </>
  
        <>
        <nav className="navbar navbar-expand-lg py-3">
          <div className="container">
            <NavLink className="navbar-brand" to="https://homey-foods.com/">
              <img
                src={require("../../assets/images/bannerLogo.png")}
                alt="logo"
                width="100px"
              />
            </NavLink>
            <button
              className={`navbar-toggler ${isToggleOpen ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              onClick={toggler}
              aria-expanded={isToggleOpen ? "true" : "false"}
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`navbar-collapse collapse ${
                isToggleOpen ? "show" : ""
              }`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ms-auto mx-auto">  {/*<--- remove mx-auto to un center the nav*/}
                <li className="nav-item">
                  <NavLink className="nav-link" to="/admin/dashboard/login">
                    Administration Login
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <a className="nav-link" href="/mealhost-registration">
                    <button
                      className="nav-link"
                      onClick={() => localStorage.setItem("Role", "MealHost")}
                      style={{ border: "#fff", background: "#fff" }}
                    >
                      MealHost <br /> Register
                    </button>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/patron-registration">
                    <button
                      className="nav-link"
                      onClick={() => localStorage.setItem("Role", "Patron")}
                      style={{ border: "#fff", background: "#fff" }}
                    >
                      Patrons <br /> Register
                    </button>
                  </a>
                </li> */}
                <li className="nav-item dropdown" >
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={toggleDropdown}
                    aria-expanded={isDropdownOpen ? "true" : "false"}
                  >
                    RESOURCES
                  </a>
                  <ul
                    style={{
                      // marginLeft:"-50vw",
                      // position: "absolute",
                      // right: "0%",
                      // top: "100%",
                      // maxWidth:"100vw"
                    }}
                    className={`dropdown-menu ${isDropdownOpen ? "show" : ""}`}
                  >
                    <li className="">
                      <a
                        className="dropdown-item"
                        target="blank"
                        href="https://www.lacrema.com/hosting-101-hosting-dinner-party/"
                        style={dropdownStyle}
                      >
                        How to Host
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.shopify.com/blog/food-photography-tips"
                        style={dropdownStyle}
                      >
                        How to take good photos of Meals Presentation
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.thekitchn.com/cooking-school-how-to-plate-food-22954318"
                        style={dropdownStyle}
                      >
                        How to make food plate look good
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.ciachef.edu/cia-new-york/"
                        style={dropdownStyle}
                      >
                        The CIA in NEW YORK
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://theculinarycook.com/learning-to-cook-online/"
                        style={dropdownStyle}
                      >
                        Learn To Cook Online: Our Site Is Always Free! - The
                        Culnary Cook
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="study.com/articles/List_of_Free_Online_Cooking_Classes_Courses_and_Learning_Materials.html"
                        style={dropdownStyle}
                      >
                        List of Free Online Cooking Classes, Courses and
                        Learning Materials
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.blueapron.com"
                        style={dropdownStyle}
                      >
                        Blue Apron: Fresh Ingredients, Original Recipes,
                        Delivered to You
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="www.yestertec.com"
                        style={dropdownStyle}
                      >
                        Premade Compact Kitchens-for home and commercial Use:
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.freshly.com/"
                        style={dropdownStyle}
                      >
                        Delivers Ingredients for Meals
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://www.fliprogram.com/"
                        style={dropdownStyle}
                      >
                        Food Liability Insurance Provider in USA
                      </a>
                    </li>
                    <li>
                      <a
                        target="blank"
                        className="dropdown-item"
                        href="https://medicalxpress.com/news/2021-03-restaurant-fare-shorten-life.html"
                        style={dropdownStyle}
                      >
                        Restaurant Meals can increase cardiovascular disease
                        risks "Journal of the Academy of Nutrition and dietetics
                        -March 2021"
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    onClick={toggleDropdown1}
                    aria-expanded={isDropdownOpen1 ? "true" : "false"}
                  >
                    UPCOMINGS
                  </a>
                  <ul
                    className={`dropdown-menu ${isDropdownOpen1 ? "show" : ""}`}
                  >
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                      Self-Carryout_Takeaways
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Delivery
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Catering-Home/office
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Catering-Commercial
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Commercial Bulk
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Plans Monthly/Yearly
                      </a>
                    </li>
                    <li>
                      <a target="blank" className="dropdown-item" href="#">
                        Paying Guests
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to="https://homey-foods.com/enquiry" >
                    enquiry
                  </NavLink>
                </li>
                <li className="nav-item">
    <a
      className="nav-link"
      href="https://translate.google.com/?sl=auto&tl=en&op=websites"
      target="_blank"
      rel="noopener noreferrer"
      title="Link to google website translator"
    >
      Translate
    </a>
  </li>
              </ul>
            </div>
          </div>
        </nav>
        
    </>
    </>
    )
};