import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { forgotPasswordValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";

export default function ForgotPassword() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const navigate = useNavigate();

  const resetPassword = (data) => {
    axios
      .post(mainServerAppUrl + "/user/reset-password", { email: data?.email })
      .then((result) => {
        // console.log(result);
        toast.success(result?.data?.message, { id: "success", duration: 3000 });
      })
      .catch((error) => {
        console.log(error);
        toast.error(error?.response?.data?.message, {
          id: "failure",
          duration: 3000,
        });
        setIsSubmitted(false);
      });
  };

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"/> */}
            <a href="/" className="site-logo">
              <img src={require("../../assets/images/bannerLogo.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main>
        <div className="home-section-1">
          <h2>Easy and Fast Access to Homey-FOODS</h2>
          <span className="text_msg">FORGOT PASSWORD</span>
        </div>
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={forgotPasswordValidation}
            initialValues={{
              email: "",
            }}
            onSubmit={(values) => {
              console.log(values);
              resetPassword(values);
              setIsSubmitted(true);
            }}
          >
            {({ errors, status, touched }) => (
              <fieldset disabled={isSubmitted}>
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        type="text"
                        name="email"
                        placeholder="Enter Your Email"
                        className={
                          "w-100 form-control" +
                          (errors.email && touched.email ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-1">
                    <button
                      type="submit"
                      value="continue"
                      className="btn w-100"
                    >
                      Continue
                    </button>
                  </div>
                </Form>
              </fieldset>
            )}
          </Formik>
          <div style={{ textAlign: "center" }}>
            <span style={{ fontWeight: "bold", fontSize: "12px" }}>
              If you have forgotten your password, simply enter your email
              address and we will send you a link to recover your password.
            </span>
          </div>
          <div style={{ textAlign: "center" }}>
            <a
              onClick={() => navigate("/user-login")}
              style={{
                color: "black",
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Back to LOGIN
            </a>
          </div>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
          Homey-FOODS is a service that brings Meal
            Hosts and Patrons together. Homey-FOODS makes no representations,
            endorsements. or warranties of any kind whatsoever of Meal Hosts and
            Customers. Both parties agree to comply to local government laws, be
            of legal age and Homey-FOODS shall not be held responsible from disputes,
            damages and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div className="social-media">
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
}
