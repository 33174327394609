import React, { useEffect, useState } from "react";
import MapIcon from "../../assets/images/map_icon.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";
import { mainServerAppUrl, socketURL } from "../../apis/mainApi";
import axios from "axios";
import { toast } from "react-hot-toast";
import { SideMenu } from "../Side-Menu/sideMenu";
import { Pagination } from "../Pagination/pagination";
import { getDistance, getPreciseDistance } from 'geolib';
import useGeoLocation from "../../hooks/useGeoLocation";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";


export const MealHostSelect = () => {
  const location = useLocation();
  const [selected, setSelected] = useState({});
  const [clicked, setClicked] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);
  const navigate = useNavigate();
  const currentLocation = useGeoLocation();

  const handleCurrentRecords = (currentRecords) => {
    setPaginatedData(currentRecords);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const placeOrder = () => {
    if (Object.keys(selected).length > 0) {
      setDisabled(true);
      const data = {
        meal_type: location.state.Array[0].meal_type,
        meal_name: selected.meal_name,
        price: selected.price,
        imageUrl: selected.imageUrl,
        order_quantity: selected.order_quantity,
        mealhost_id: selected?.mealhost_id?.membership_no,
        message: selected.message ? selected.message : null,
      };
      axios
        .post(mainServerAppUrl + "/orders", data)
        .then((result) => {
          toast.success("Your Order has been Placed.", {
            id: "success",
            duration: 3000,
          });
          initiatePaymentProcess(result.data);
        })
        .catch((error) => {
          toast.error(error?.response?.data.message, {
            id: "failure",
            duration: 3000,
          });
        });
    } else {
      toast.error("Select an item first", {
        id: "failure400",
        duration: 3000,
      });
    }
  };

  const initiatePaymentProcess = (data) => {
    axios
      .post(mainServerAppUrl + "/patron/payment", { data })
      .then((result) => {
        toast.dismiss("success");
        toast.success(result.data.message, { id: "invoice", duration: 3000 });
        setTimeout(() => {
          navigate("/patron-home");
        }, 3100);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const calculateDistance = (data) => {
    let distance = getDistance(
      { latitude: data?.mealhost_id?.location?.coordinates[1], longitude: data?.mealhost_id?.location?.coordinates[0] },
      { latitude: location.state.location.latitude, longitude: location.state.location.longitude }
    );

    distance *= 0.0006213
    
    return distance.toFixed(2)
    // alert(`Distance\n\n${dis} Meter\nOR\n${dis / 1000} KM`);
  };

  return (
    <div className="max-resolution">
       <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <span className="text_msg">PATRON AVAILABLE MEAL HOSTS</span>
        </div>
      <Header/>
      <main>
        <div className="home-section-1 position-relative">
          {/* <SideMenu /> */}
          <h2>Easy and Fast Access to Homey-FOODS</h2>
        </div>
        <div className="custom-form centered pb-4">
          <form>
            <div className="product_list">
              {paginatedData && paginatedData.map((data, i) => (
                <div className="product_item" key={i}>
                  <div className="product_item_top">
                    <div className="row m-0">
                      <div className="col-5 pl-0">
                        {/* <img src={data?.imageUrl} /> */}
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                            data?.imageUrl
                          }
                          alt={data?.imageUrl}
                        />
                        {/* {console.log(data)} */}
                      </div>
                      <div className="col-7 pl-0">
                        <h2>{data?.meal_name}</h2>
                        <p>
                          {data?.mealhost_id?.first_name}{" "}
                          {data?.mealhost_id?.last_name}
                          <br />
                          {data?.mealhost_id?.address_line1},{" "}
                          {data?.mealhost_id?.address_line2}
                        </p>
                        <p>"{data?.mealhost_id?.profile}"</p>
                        <span className="price">{data?.price}$</span>
                        <br />
                        <Rating
                          initialValue={data?.mealhost_id?.ratings}
                          readonly
                          allowFraction
                          size={23}
                          title={data?.mealhost_id?.ratings}
                        />
                        <span style={{ fontSize: "15px" }}>
                          {"(" + data?.mealhost_id?.num_of_reviews + ")"}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="product_item_bottom">
                    <p className="miles_text">
                      {" "}
                      <img src={MapIcon} width="18" height="18" />{" "}
                      <span>{calculateDistance(data)} Miles Away</span>
                    </p>
                    <a
                      onClick={() => {
                        setSelected(
                          data,
                          (data.order_quantity = location.state.order_quantity)
                        );
                        setClicked(i);
                        // socket.emit('join', data?.mealhost_id?.membership_no);
                      }}
                      className="btn"
                      style={clicked === i ? { opacity: 0.5 } : {}}
                    >
                      {clicked === i ? "SELECTED" : "SELECT"}
                    </a>
                  </div>
                </div>
              ))}
            </div>
            <div className="col-12 p-0">
              <input
                type="submit"
                value="PLACE ORDER"
                className="btn w-100"
                disabled={disabled}
                onClick={(e) => {
                  e.preventDefault();
                  placeOrder();
                }}
              />
            </div>
          </form>
        </div>
        <Pagination
          data={location?.state?.Array}
          handleCurrentRecords={handleCurrentRecords}
        />
      </main>
      <Footer/>
    </div>
  );
};
