import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./App.css";
import "./config/http";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

const PatronTheme = React.lazy(() => import("./components/Theme/patronTheme"));
const MealHostTheme = React.lazy(() =>
  import("./components/Theme/mealHostTheme")
);
const HomeTheme = React.lazy(() => import("./components/Theme/homeTheme"));

const ThemeSelector = ({ children }) => {
  const CHOSEN_THEME = localStorage.getItem("Role");
  return (
    <>
      <React.Suspense
        fallback={
          <></>
          // <div
          //   style={{
          //     display: "flex",
          //     position: "fixed",
          //     top: "15%",
          //     bottom: "0",
          //     left: "10%",
          //     right: "0",
          //     width: "200px",
          //     height: "100px",
          //     margin: "auto",
          //     fontSize: "20px",
          //   }}
          // >
          //   Loading...
          // </div>
        }
      >
        {CHOSEN_THEME === "Patron" && <PatronTheme />}
        {CHOSEN_THEME === "MealHost" && <MealHostTheme />}
        {/* {!CHOSEN_THEME && <PatronTheme />} */}
        {!CHOSEN_THEME && <HomeTheme />}
      </React.Suspense>
      {children}
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeSelector>
      <App />
    </ThemeSelector>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
