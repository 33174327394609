import axios from "axios";
import React, { useEffect, useState } from "react";
import { getAuthUser } from "../Authentication/authHelper";
import { mainServerAppUrl } from "../../apis/mainApi";
// import { SideMenu } from "../Side-Menu/sideMenu";
import moment from "moment";
import { Pagination } from "../Pagination/pagination";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const MealHostServedHistory = () => {
  const [meals, setMeals] = useState([]);
  const [noMeals, setnoMeals] = useState(false);
  const [paginatedData, setPaginatedData] = useState([]);

  const handleCurrentRecords = (currentRecords) => {
    setPaginatedData(currentRecords);
  };

  const data = {
    mealhost_id: getAuthUser().membership_no,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axios
      .post(mainServerAppUrl + "/user/mealhost/history", data)
      .then((result) => {
        setMeals(result.data);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 400) {
          setnoMeals(true);
        }
      });
  }, []);

  return (
    <div className="max-resolution">
       <section className="title_box">
          <h2>MEAL HOST HISTORY OF SERVED MEALS</h2>
      </section>
      <Header/>
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container position-relative">
          <div className="custom-form centered mb-4">
            {noMeals ? (
              <div
                className="w-100 form-control pt-0"
                style={{ textAlign: "center" }}
              >
                No Meal History Found.
              </div>
            ) : (
              <div className="w-100 form-control pt-0">
                {paginatedData.map((meals, ind) => (
                  <div className="green_box_history mt-4" key={ind}>
                    <p>Membership No. - {meals.patron_id}</p>
                    <p>Patron Name - {meals.patron}</p>
                    <p>
                      Date -{" "}
                      {moment
                        .utc(meals.ordered_On)
                        .local()
                        .format("DD-MM-YYYY HH:mm")}
                    </p>
                    <p>Meal Served - {meals.meal_name}</p>
                    <p>Meal Price - ${meals.price * meals.order_quantity}</p>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <Pagination data={meals} handleCurrentRecords={handleCurrentRecords} />
      </main>
      <Footer/>
    </div>
  );
};
