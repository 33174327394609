import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Link,
  Checkbox,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import Iconify from "../components/iconify";

import { mainServerAppUrl } from "../../../apis/mainApi";
import { getAuthUser } from "../../Authentication/authHelper";
import { toast } from "react-hot-toast";

// ----------------------------------------------------------------------

export function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = () => {
    axios
      .post(mainServerAppUrl + "/user/login", {
        email,
        password,
      })
      .then((result) => {
        localStorage.setItem("_token", result?.data?.token);
        localStorage.setItem("Role", getAuthUser()?.role);
        navigateToHomePage();
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error?.response?.data?.message, {
          id: "error",
          duration: 3000,
        });
      });
  };

  const navigateToHomePage = () => {
    switch (getAuthUser().role) {
      case "Patron":
        toast.error("Only Admin can Login", { id: "patron", duration: 3000 });
        localStorage.removeItem("_token");
        localStorage.removeItem("Role");
        break;
      case "MealHost":
        toast.error("Only Admin can Login", { id: "mealhost", duration: 3000 });
        localStorage.removeItem("_token");
        localStorage.removeItem("Role");
        break;
      case "Admin":
        navigate("/admin/dashboard");
        break;
      default:
        navigate("/login");
        console.log(
          "Role can either be one of them - admin, patron or mealhost"
        );
        console.log("Your Role -> ", getAuthUser().role);
    }
  };

  const txtFieldStyle = {
    backgroundColor:"white", borderRadius:"6px",
    "& label.Mui-focused" : {
      fontWeight:"bold",
      top:"-10px",
      color:"white"
    },
    "& label.MuiInputLabel-shrink": {
      fontWeight:"bold",
      top:"-10px",
      color:"white" // Change the color to your desired color when value is entered
    },
  }

  return (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="Email Address"
          onChange={(e) => setEmail(e.target.value)}
          sx={txtFieldStyle}
        />

        <TextField
          name="password"
          label="Password"
          onChange={(e) => setPassword(e.target.value)}
          type={showPassword ? "text" : "password"}
          sx={txtFieldStyle}
          
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  edge="end"
                >
                  <Iconify
                    icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ my: 2 }}
      >
        {/* <Link variant="subtitle2" underline="hover" onClick={() => (navigate('/user-login'), localStorage.clear())} style={{ cursor: 'pointer', marginLeft: '22rem' }}>
          Go to User Login
        </Link> */}
      </Stack>

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        onClick={loginUser}
      >
        Login
      </LoadingButton>
    </>
  );
}
