import React, { useState } from "react";
import axios from "axios";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { loginValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { getAuthUser } from "../Authentication/authHelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

export default function LoginUser() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const loginUser = (data) => {
    axios
      .post(mainServerAppUrl + "/user/login", {
        email: data?.email,
        password: data?.password,
      })
      .then((result) => {
        // console.log(result);
        localStorage.setItem("_token", result?.data?.token);
        localStorage.setItem("Role", getAuthUser()?.role);
        navigateToHomePage();
      })
      .catch((error) => {
        console.log(error.response)
        toast.error(error?.response?.data?.message, {
          id: "error",
          duration: 3000,
        });
        if (error.response.status === 401 || error.response.status === 404) {
          localStorage.setItem('email', data?.email)
          localStorage.setItem('password', data?.password)
          localStorage.setItem('Role', 'MealHost');
          setTimeout(() => {
            navigate('/mealhost-payment');
          }, 3100)
        }
      });
  };

  const navigateToHomePage = () => {
    switch (getAuthUser().role) {
      case "Patron":
        window.location.replace("/patron-home");
        break;
      case "MealHost":
        window.location.replace("/mealhost-home");
        break;
      case "Admin":
        toast.success('Hello Admin! Please login through the dashboard.', { id: 'admin', duration: 3000 });
        localStorage.clear();
        setTimeout(() => {
          navigate("/admin/dashboard/login");
        }, 3100)
        break;
      default:
        navigate("/user-login");
        console.log(
          "Role can either be one of them - admin, patron or mealhost"
        );
        console.log("Your Role -> ", getAuthUser().role);
    }
  };

  return (
    <div className="max-resolution">
      <header>
        <div className="header-info">
          <div className="left-part">
            {/* <img src="common/images/food1.png" className="img-fluid" alt="Register Banner"/> */}
            <a href="#" className="site-logo">
              <img src={require("../../assets/images/bannerLogo.png")} />
            </a>
          </div>
          <div className="right-part">
            <h1>Homey-FOODS</h1>
            <p>
              Throughout human history in all cultures food is an important
              basis of social bonding. With our increasing human family
              worldwide. Homey-FOODS offering a venue for all to
              enjoy unique cuisines, healthy regional foods by many who enjoy
              cooking and eager to serve variety of foods.{" "}
            </p>
            <p>
              <span>
                “Restaurant Meals can increase cardiovascular disease
                risk”Journal of the Academy of Nutrition and Dietetics-March
                2021
              </span>
            </p>
          </div>
        </div>
      </header>
      <main
        style={{
          backgroundColor: "coral",
          padding: "0rem",
        }}
      >
        <div
          style={{
            backgroundColor: "#0e9b44",
            marginBottom: "40px",
            padding: "20px",
          }}
        >
          <h2
            style={{
              color: "#fff",
              fontSize: "12px",
              fontWeight: "700",
              lineHeight: "14px",
              textAlign: "center",
            }}
          >
            Easy and Fast Access to Homey-FOODS
          </h2>
          <span
            style={{
              fontSize: "24px",
              fontWeight: 700,
              color: "#fff",
              display: "block",
              textAlign: "center",
            }}
          >
            USER- LOGIN
          </span>
        </div>
        {/* <div ></div> */}
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={loginValidation}
            initialValues={{
              email: "",
              password: "",
            }}
            onSubmit={(values) => {
              loginUser(values);
            }}
          >
            {({ errors, status, touched }) => (
              <Form>
                <div className="row">
                  <div className="col-12">
                    <Field
                      type="text"
                      name="email"
                      placeholder="Enter Your Email"
                      className={
                        "w-100 form-control" +
                        (errors.email && touched.email ? " is-invalid" : "")
                      }
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                  <div className="col-12">
                    <Field
                      type={showPassword ? "text" : "password"}
                      name="password"
                      placeholder="Enter Your Password"
                      className={
                        "w-100 form-control" +
                        (errors.password && touched.password
                          ? " is-invalid"
                          : "")
                      }
                    />
                    <div
                      className="p-viewer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      <i
                        className={
                          showPassword
                            ? "fa-solid fa-eye fa-lg"
                            : "fa-sharp fa-solid fa-eye-slash fa-lg"
                        }
                      ></i>
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="form_invalid"
                    />
                  </div>
                </div>
                <div className="col-12 p-3">
                  <button type="submit" value="LOGIN" className="btn w-100">
                    LOGIN
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div style={{ textAlign: "center" }}>
            <a
              onClick={() => navigate("/forgot-password")}
              style={{
                color: "black",
                cursor: "pointer",
                fontWeight: "bold",
                fontSize: "12px",
              }}
            >
              Forgot Password?
            </a>
          </div>
          <div style={{ textAlign: "center" }}>
            <p style={{ fontWeight: "bold", fontSize: "12px" }}>
              Don't Have An Account Yet? &nbsp;
              <a onClick={() => navigate("/")} style={{ fontSize: "12px", color: "white", cursor: "pointer" }}>
                Sign Up!
              </a>{" "}
            </p>
          </div>
        </div>
      </main>
      <footer>
        <div className="footer-content">
          <div className="disclaimer">
            <p className="pb-2">DISCLAIMER</p>
          </div>
          <p>
          Homey-FOODS is a service that brings Meal
            Hosts and Patrons together. Homey-FOODS makes no representations,
            endorsements. or warranties of any kind whatsoever of Meal Hosts and
            Customers. Both parties agree to comply to local government laws, be
            of legal age and Homey-FOODS shall not be held responsible from disputes,
            damages and controversies.
          </p>
        </div>
        <div className="footer_bottom">
          <a href="https://www.homey-foods.com" className="site_link">
            www.homey-foods.com
          </a>
          <div
            className="social-media"
            style={{
              display: "flex",
              justifyContent: "center",
              padding: "10px 0",
            }}
          >
            <ul>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-linkedin"></i>{" "}
                </a>
              </li>
              <li>
                <a href="#" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
          <p>© 2023 Homey-FOODS</p>
        </div>
      </footer>
    </div>
  );
}
