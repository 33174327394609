import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getAuthUser } from "../Authentication/authHelper";
import { toast } from "react-hot-toast";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
// import 'react-confirm-alert/src/react-confirm-alert.css'

export const SideMenu = (props) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showPasswordPopup, setShowPasswordPopup] = useState(false);
  const [enteredPassword, setEnteredPassword] = useState("");
  const navigate = useNavigate();
  const menuRef = useRef(null);


  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  // const logout = () => {
  //   confirmAlert({
  //     // title: 'Confirm to submit',
  //     message: 'Are you sure you want to logout?',
  //     buttons: [
  //       {
  //         label: 'Yes',
  //         onClick: () => {   
  //           localStorage.clear();
  //           navigate("/user-login");
  //         }
  //       },
  //       {
  //         label: 'No',
  //         // onClick: () => alert('Click No')
  //       }
  //     ]
  //   })
  // };

  const handleClickOutside = (event) => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setShowDropdown(false)
      // setShowPasswordPopup(false);
      ;
    }
  };

  const handleToggleClick = (event) => {
    event.stopPropagation();
    if(showDropdown ===true){
      setShowPasswordPopup(false);
    }else{
      setShowPasswordPopup(!showPasswordPopup);
    }
  };

  const handlePasswordSubmit = () => {
    axios
    .post(mainServerAppUrl + "/user/links", {
      email: getAuthUser().email,
      password: enteredPassword
    })
    .then((result) => {
    setShowPasswordPopup(false);
    setShowDropdown(true);
    setEnteredPassword(null)
    })
    .catch((error) => {
      console.log(error.response)
      toast.error(error?.response?.data?.message, {
        id: "error",
        duration: 3000,
      });
    });


    // const correctPassword = "yourCorrectPasswordHere";

    // if (enteredPassword === correctPassword) {
    //   setShowPasswordPopup(false);
    //   setShowDropdown(!showDropdown);
    // } else {
    //   // Show an error message or perform other actions
    // }
  };

  return (
    <>
      {/* <div className="d-flex justify-content-end mb-2 ">
        <button
          type="button"
          className="small_btn-box"
          onClick={handleToggleClick}
          style={{color:"white"}}
        >Links<br/>
          <span className="small_btn">Links</span>
        </button>
      </div>
      <div
        className="menu_links"
        style={showDropdown ? { display: "block" } : { display: "none" }}
        ref={menuRef}
        onClick={(event) => event.stopPropagation()}
      > */}
      <div className="d-flex justify-content-end mb-2 ">
        <button
          type="button"
          className="small_btn-box"
          onClick={handleToggleClick}
          style={{ color: "white" }}
        >
          Links<br />
          <span className="small_btn">Links</span>
        </button>
      </div>
      {showPasswordPopup && (
        <div className="password-dropdown">
          <input
            type="password"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
            placeholder="Enter Password"
            className="link-inp"
          />
          <button onClick={handlePasswordSubmit} className="btn-links">Open</button>
        </div>
      )}
      <div
        className="menu_links"
        style={showDropdown ? { display: "block" } : { display: "none" }}
        ref={menuRef}
        onClick={(event) => event.stopPropagation()}
      >
        <ul>
          {getAuthUser()?.role === "Patron" ? (
            <>
              <li>
                <a
                  onClick={ () =>  window.open('/', '_blank')}
                  className="menu_links_1"
                >
                  Homey-FOODS Home
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/update-patron-info")}
                  className="menu_links_1"
                >
                  Update Registration
                </a>
              </li>
              <li>
                {/* <a
                  onClick={() => navigate("/patron-ratings")}
                  className="menu_links_1"
                >
                  Rate The Meal Host
                </a> */}
                 <a
                  onClick={() => navigate("/patron-stars")}
                  className="menu_links_1"
                >
                  My Earned Stars
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/patron-meal-history")}
                  className="menu_links_1"
                >
                  Meals History
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/patron-mealhost-today")}
                  className="menu_links_1"
                >
                  MealHost Today
                </a>
              </li>
              {/* <li>
                <a
                  onClick={() => navigate("/patron-payment")}
                  className="menu_links_1"
                >
                  Order Payment
                </a>
              </li> */}
            </>
          ) : (
            <>
              <li>
                <a 
                onClick={ () =>  window.open('/', '_blank')}
                className="menu_links_1"
                >
                  Homey-FOODS Home</a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/update-mealhost-info")}
                  className="menu_links_1"
                >
                  Update Registration
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/mealhost-instructions", {state:"instruction"})}
                  className="menu_links_1"
                >
                  Instructions
                </a>
              </li>
              <li>
              <a
                  onClick={() => navigate("/mealhost-instructions", {state:"updatemenu"})}
                  className="menu_links_1"
                >
                  Update Meal Menu
                </a>

                {/* <a href="/update-menu">Update Meal Menu</a> */}
              </li>
              <li>
                {/* <a
                  onClick={() => navigate("/mealhost-ratings")}
                  className="menu_links_1"
                >
                  Rate The Patron
                </a> */}
                 <a
                  onClick={() => navigate("/mealhost-stars")}
                  className="menu_links_1"
                >
                  My Earned Stars
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/mealhost-served-history")}
                  className="menu_links_1"
                >
                  Orders History
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/mealhost-accepted-meals")}
                  className="menu_links_1"
                >
                  Accepted Orders
                </a>
              </li>
              <li>
                <a
                  onClick={() => navigate("/pending-order")}
                  className="menu_links_1"
                >
                  Pending Orders
                </a>
              </li>
              {/* <li>
                <a
                  onClick={() => navigate("/mealhost/subscription")}
                  className="menu_links_1"
                >
                  Subscription
                </a>
              </li> */}
            </>
          )}
          {/* <li>
            <a href="#">Resources</a>
          </li> */}
          <li>
            <a onClick={() => navigate("/feedback")} className="menu_links_1">
              Email Admin
            </a>
          </li>
          {/* <li>
            <a
              onClick={() => navigate("/change-password")}
              className="menu_links_1"
            >
              Change Password
            </a>
          </li> */}
          {/* <li>
            <a onClick={() => logout()} className="menu_links_1">
              Logout
            </a>
          </li> */}
        </ul>
      </div>
    </>
  );
};
