import React, { useState } from "react";
import { SideMenu } from "../Side-Menu/sideMenu";
import { mainServerAppUrl } from "../../apis/mainApi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { emailAdminValidation } from "../Validation/ValidationSchema";
import { toast } from "react-hot-toast";
import axios from "axios";
import { ThcmNavbar } from "./thcmNavbar";
import { ThcmFooter } from "./thcmFooter";
import { useEffect } from "react";
import { CountryCodes } from "../../common/countryCodes";


export const Enquiry = () => {

  useEffect(()=>{
    window.scrollTo(0,0)
  },[])

  const [disabled, setDisabled] = useState(false);
  const [role, setRole] = useState(localStorage.getItem('Role'));

  const emailAdmin = (values) => {
    setDisabled(true);
    axios
      .post(mainServerAppUrl + '/user/enquiry', values)
      .then((result) => {
        toast.success(result.data.message, { id: 'mail sent', duration: 3000 });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.message, { id: 'error', duration: 3000 });
      })
  };

  return (
    <div className="max-resolution">
      <ThcmNavbar />
      <div className="home-section-1 title_box">
        {/* <SideMenu /> */}
        <span
          style={{
            display: 'block',
            fontWeight: '900',
            color: '#fff',
            fontSize: '18px',
            textAlign: 'center'
          }}>
          {role === 'Patron' ? "PATRON - " : role === 'MealHost' ? "MEAL HOST - " : ""}
          SEND EMAIL TO ADMIN
        </span>
      </div>

      <main style={{ padding: '0rem' }}>
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={emailAdminValidation}
            initialValues={{
              name: '',
              email: '',
              phone: '',
              message: '',
              country_code: '',
            }}
            onSubmit={(values) => {
              console.log(values)
              emailAdmin(values)
            }}
          >
            {({ errors, touched, setFieldValue }) => (
              <fieldset>
                <Form>
                  <div className="row">
                    <div className="col-6 mx-auto">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <Field
                          type="text"
                          name="name"
                          className={
                            "form-control" +
                            (errors.name && touched.name ? " is-invalid" : "")
                          }
                          placeholder="Enter your Name"
                          required
                        />
                        <ErrorMessage
                          name="name"
                          component="div"
                          className="form_invalid"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <Field
                          type="email"
                          name="email"
                          className={
                            "form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                          placeholder="Enter your Email"
                          required
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="form_invalid"
                          style={{ color: "red" }}
                        />
                      </div>
                      {/* <div className="form-group">
                        <label htmlFor="email">Phone No.</label>
                        <Field
                          type="number"
                          name="phone"
                          className={
                            "form-control" +
                            (errors.phone && touched.phone ? " is-invalid" : "")
                          }
                          placeholder="Enter your Phone No."
                          required
                        />
                        <ErrorMessage
                          name="phone"
                          component="div"
                          className="form_invalid"
                          style={{ color: "red" }}
                        />
                      </div> */}
                       <div className="form-group">
                      <div className="row">
                        <div className="col-4">
                          <div className="select-wrapper">
                            Country Code
                            <Field
                              as="select"
                              name="country_code"
                              required
                              className="w-100 form-control"
                              onChange={(e) => {
                                const { value } = e.target;
                                setFieldValue('country_code', value); 
                              }}
                            > <option value="" disabled>+</option>
                              {CountryCodes.map((item) => (
                                <option key={item.iso} value={item.code}>
                                  +{item.code}
                                </option>
                              ))}
                            </Field>
                            <span className="select_arrow"></span>
                          </div>
                        </div>
                        <div className="col-8">
                          Phone No.
                          <Field
                            type="number"
                            name="phone"
                            placeholder="Phone"
                            className={
                              "w-100 form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="form_invalid"
                          />
                        </div>
                      </div>
                    </div>
                      <div className="form-group">
                        <label htmlFor="message">Message</label>
                        <Field
                          component="textarea"
                          name="message"
                          rows="4"
                          cols="50"
                          placeholder="Write your message here, it will be e-mailed to the Admin"
                          maxLength="250"
                          style={{ height: '250px', resize: '', }}
                          className={
                            "w-100 form-control" +
                            (errors.message && touched.message ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="form_invalid"
                          style={{ color: "red" }}
                        />
                      </div>
                      <div className="row text-center">
                        <ErrorMessage
                          name="message"
                          component="div"
                          className="form_invalid"
                          style={{ color: "red" }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-2 p-3 mx-auto">
                        <button
                          type="submit"
                          value="REGISTER"
                          className="btn w-100"
                          disabled={disabled}
                          style={{
                            border: "3px solid #fafa33e8",
                            transition: "background-color 0.3s, color 0.3s"
                          }}
                          onMouseEnter={(e) => {
                            e.target.style.backgroundColor = "#fafa33e8";
                            e.target.style.color = "white";
                            // Add any additional styles or logic for the hover effect
                          }}
                          onMouseLeave={(e) => {
                            e.target.style.backgroundColor = "";
                            e.target.style.color = "";
                            // Add any additional styles or logic for when the mouse leaves the button
                          }}
                        >
                          SEND MESSAGE
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              </fieldset>
            )}
          </Formik>
        </div>
      </main>
      <ThcmFooter />
    </div>
  );
};
