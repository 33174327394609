import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import moment from "moment";
import Visa from "../../assets/images/visa-card.svg";
import MasterCard from "../../assets/images/mastercard.svg";
import Amex from "../../assets/images/amex.svg";
import {
  updateMealhostRegistrationValidation,
  updateRegistrationValidation,
} from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { getAuthUser } from "../Authentication/authHelper";
import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CountryCodes } from "../../common/countryCodes";
import { ScrollToFieldError } from "../Validation/scrollToFieldError";
import MaskedInput from "react-text-mask";
import {
  accountNumberMask,
  cardNumberMask,
  routingNumberMask,
} from "../Validation/cardNumberMask";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const UpdateMealHostRegistration = () => {
  const [updateDetails, setUpdateDetails] = useState(false);
  const [showPassword, setShowPassword] = useState(false);


  const user = getAuthUser();

  const getCardDetails = () => {
    const cardDetails = user?.card_details?.[0];
    return {
      card_type: cardDetails?.card_type,
      card_expiry: cardDetails?.card_expiry,
      card_number: cardDetails?.card_number,
      cvv: cardDetails?.cvv,
    };
  };

  return (
    <div className="max-resolution">
      <section className="title_box">
        {/* <div className="container position-relative"> */}
        {/* <SideMenu /> */}
        <h2>MEAL HOST UPDATE REGISTRATION</h2>
        {updateDetails && (
          <h2>
            Your details have been successfully updated.
            <br />
            Date: {moment(new Date()).format("Do MMM YYYY")}
          </h2>
        )}
        {/* </div> */}
      </section>
      <Header />
      <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="custom-form centered pb-4">
            <span
              style={{
                fontSize: "19px",
                fontWeight: "700",
                display: "flex",
                justifyContent: "center",
              }}
            >
              Your Membership Number - {user.membership_no}
            </span>
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={updateMealhostRegistrationValidation}
              initialValues={{
                registration_date: moment(user?.registration_date).format(
                  "YYYY-MM-DD"
                ),
                profile: user?.profile,
                first_name: user?.first_name,
                last_name: user?.last_name,
                dob: {
                  day: user?.dob?.day,
                  month: user?.dob?.month,
                  year: user?.dob?.year
                },
                address_line1: user?.address_line1,
                address_line2: user?.address_line2,
                city: user?.city,
                phone: user?.phone,
                email:user?.email,
                password:user?.password,
                country: user?.country,
                state: user?.state,
                zip_code: user?.zip_code,
                country_code: user?.country_code,
                account_holder_name: user?.account_holder_name,
                bank_name: user?.bank_name,
                bank_address: user?.bank_address,
                ssn_last_4:user?.ssn_last_4,
                account_no: user?.account_no,
                routing_no: user?.routing_no,
                card_details: getCardDetails(),
                role: user?.role,
              }}
              onSubmit={(values) => {
                axios
                  .patch(mainServerAppUrl + "/user/update-details", values)
                  .then((result) => {
                    setUpdateDetails(true);
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    toast.success(result?.data?.message, {
                      id: "success",
                      duration: 3000,
                    });
                    localStorage.setItem("_token", result?.data?.token);
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message, {
                      id: "failure",
                      duration: 3000,
                    });
                  });
              }}
            >
              {({
                errors,
                status,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        type="date"
                        name="registration_date"
                        placeholder="Date of Registration"
                        className={
                          "w-100 form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                        disabled
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="first_name"
                        placeholder="First Name"
                        className={
                          "w-100 form-control" +
                          (errors.first_name && touched.first_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="first_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="last_name"
                        placeholder="Last Name"
                        className={
                          "w-100 form-control" +
                          (errors.last_name && touched.last_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="last_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                        <br/>
                        <b>Date of birth</b>
                      </div>
                      <div className="col-4">
      <Field
        type="number"
        name="dob.day"
        placeholder="Day"
        className={
          "w-100 form-control" +
          (errors.dob?.day && touched.dob?.day ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.day" component="div" className="invalid-feedback" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.month"
        placeholder="Month"
        className={
          "w-100 form-control" +
          (errors.dob?.month && touched.dob?.month ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.month" component="div" className="invalid-feedback" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.year"
        placeholder="Year"
        className={
          "w-100 form-control" +
          (errors.dob?.year && touched.dob?.year ? " is-invalid" : "")
        }
      />
      <ErrorMessage name="dob.year" component="div" className="invalid-feedback" />
      </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="address_line1"
                        placeholder="Address line 1"
                        className={
                          "w-100 form-control" +
                          (errors.address_line1 && touched.address_line1
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="address_line1"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="address_line2"
                        placeholder="Address line 2"
                        className={
                          "w-100 form-control" +
                          (errors.address_line2 && touched.address_line2
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="address_line2"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="city"
                        placeholder="City"
                        className={
                          "w-100 form-control" +
                          (errors.city && touched.city ? " is-invalid" : "")
                        }
                      />
                      <ErrorMessage
                        name="city"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <div className="select-wrapper">
                        <CountryDropdown
                          name="country"
                          value={values.country}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                          className={
                            "w-100 form-control" +
                            (errors.country && touched.country
                              ? " is-invalid"
                              : "")
                          }
                          defaultOptionLabel="Country"
                        />
                        <span className="select_arrow"></span>
                        <ErrorMessage
                          name="country"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="select-wrapper">
                        <RegionDropdown
                          name="state"
                          country={values.country}
                          value={values.state}
                          onChange={(_, e) => handleChange(e)}
                          onBlur={handleBlur}
                          className={
                            "w-100 form-control" +
                            (errors.state && touched.state ? " is-invalid" : "")
                          }
                          defaultOptionLabel="State"
                        />
                        <span className="select_arrow"></span>
                        <ErrorMessage
                          name="state"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="zip_code"
                        placeholder="Zip Code"
                        className={
                          "w-100 form-control" +
                          (errors.zip_code && touched.zip_code
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="zip_code"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-4">
                          <div className="select-wrapper">
                            <Field
                              as="select"
                              name="country_code"
                              className="w-100 form-control"
                            >
                              {CountryCodes.map((item) => (
                                <option key={item.iso} value={item.code}>
                                  +{item.code}
                                </option>
                              ))}
                            </Field>
                            <span className="select_arrow"></span>
                          </div>
                        </div>
                        <div className="col-8">
                          <Field
                            type="number"
                            name="phone"
                            placeholder="Phone"
                            className={
                              "w-100 form-control" +
                              (errors.phone && touched.phone
                                ? " is-invalid"
                                : "")
                            }
                          />
                          <ErrorMessage
                            name="phone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="profile"
                        placeholder="Profile"
                        className={
                          "w-100 form-control" +
                          (errors.profile && touched.profile
                            ? " is-invalid"
                            : "")
                        }
                        maxLength={200}
                      />
                      <ErrorMessage
                        name="profile"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                     <div className="col-12">
                        <Field
                          type="text"
                          name="email"
                          placeholder="Email"
                          className={
                            "w-100 form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          className={
                            "w-100 form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                        />
                        <div
                          className="p-viewer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword
                                ? "fa-solid fa-eye"
                                : "fa-sharp fa-solid fa-eye-slash"
                            }
                          ></i>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                    
                    <div className="col-12">
                      <br />
                      <b>
                        Your Bank Information for wiring payments for meals
                        served by you.
                      </b>
                      <Field
                        type="text"
                        name="account_holder_name"
                        placeholder="Account holder's Name"
                        className={
                          "w-100 form-control" +
                          (errors.account_holder_name && touched.account_holder_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="account_holder_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="bank_name"
                        placeholder="Bank Name"
                        className={
                          "w-100 form-control" +
                          (errors.bank_name && touched.bank_name
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="bank_name"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field
                        type="text"
                        name="bank_address"
                        placeholder="Bank Address"
                        className={
                          "w-100 form-control" +
                          (errors.bank_address && touched.bank_address
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="bank_address"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    {values.country === "United States" && (
  <div className="col-12">
    <Field
      type="text"
      name="ssn_last_4"
      placeholder="Last 4 Digits of SSN"
      className={
        "w-100 form-control" +
        (errors.ssn_last_4 && touched.ssn_last_4 ? " is-invalid" : "")
      }
    />
    <ErrorMessage
      name="ssn_last_4"
      component="div"
      className="invalid-feedback"
    />
  </div>
)}

                    <div className="col-12">
                      <Field name="account_no">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            type="text"
                            mask={accountNumberMask}
                            guide={false}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                              setFieldValue("account_no", unmaskedValue);
                            }}
                            onBlur={handleBlur}
                            placeholder="Account Number"
                            className={
                              "w-100 form-control" +
                              (errors.account_no && touched.account_no
                                ? " is-invalid"
                                : "")
                            }
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="account_no"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12">
                      <Field>
                        {({ field }) => (
                          <MaskedInput
                            // {...field}

                            type="text"
                            name="routing_no"
                            mask={routingNumberMask}
                            guide={false}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                              setFieldValue("routing_no", unmaskedValue);
                            }}
                            onBlur={handleBlur}
                            placeholder="Routing Number"
                            className={
                              "w-100 form-control" +
                              (errors.routing_no && touched.routing_no
                                ? " is-invalid"
                                : "")
                            }
                            value={values.routing_no}
                          />
                        )}
                      </Field>
                      <ErrorMessage
                        name="routing_no"
                        component="div"
                        className="invalid-feedback"
                      />
                    </div>
                    <div className="col-12 card_section">
                      <br />
                      <b>Your Credit Card Information for your subscription.</b>
                      <span className="form_heading">Card Type</span>
                      <div className="d-flex align-items-center">
                        <div className="col-3 p-0">
                          <button
                            className="visa_card"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue("card_details.card_type", "Visa");
                            }}
                          >
                            <img src={Visa} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <button
                            className="master_card"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue(
                                "card_details.card_type",
                                "Master Card"
                              );
                            }}
                          >
                            <img src={MasterCard} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <button
                            className="american_express"
                            onClick={(e) => {
                              e.preventDefault();
                              setFieldValue(
                                "card_details.card_type",
                                "American Express"
                              );
                            }}
                          >
                            <img src={Amex} />
                          </button>
                        </div>
                        <div className="col-3 p-0">
                          <span
                            className="form_heading p-0"
                            onClick={() => {
                              setFieldValue("card_details.card_type", "");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Others
                          </span>
                        </div>
                      </div>
                      <input
                        type="text"
                        name="card_details.card_type"
                        value={values.card_details.card_type}
                        onChange={handleChange}
                        placeholder="Card Type"
                        className={
                          "w-100 form-control" +
                          (errors.card_details?.card_type &&
                          touched.card_details?.card_type
                            ? " is-invalid"
                            : "")
                        }
                        disabled={
                          values.card_details.card_type === "Visa" ||
                          values.card_details.card_type ===
                            "American Express" ||
                          values.card_details.card_type === "Master Card"
                        }
                      />
                      <ErrorMessage
                        name="card_details.card_type"
                        component="div"
                        className="invalid-feedback"
                      />

                      <Field name="card_details.card_number">
                        {({ field }) => (
                          <MaskedInput
                            {...field}
                            mask={cardNumberMask}
                            type="text"
                            guide={false}
                            // onChange={handleChange}
                            onChange={(e) => {
                              const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                              setFieldValue("card_details.card_number", unmaskedValue);
                            }}
                            onBlur={handleBlur}
                            placeholder="Card Number"
                            className={
                              "w-100 form-control" +
                              (errors.card_number && touched.card_number
                                ? " is-invalid"
                                : "")
                            }
                          />
                        )}
                      </Field>

                      <ErrorMessage
                        name="card_details.card_number"
                        component="div"
                        className="form_invalid"
                      />
                      <div className="d-flex" style={{ marginLeft: "-4%" }}>
                        <div>
                          <Field name="card_details.card_expiry">
                            {({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={[/[0-1]/, /[1-2]/, "/", /[2-9]/, /[3-9]/]}
                                type="text"
                                guide={false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="MM/YY"
                                className={
                                  "w-50 form-control ml-3 mr-3 text-center" +
                                  (errors.card_expiry && touched.card_expiry
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="card_details.card_expiry"
                            component="div"
                            className="form_invalid"
                          />
                        </div>
                        <div>
                          <Field name="card_details.cvv">
                            {({ field }) => (
                              <MaskedInput
                                {...field}
                                mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                type="password"
                                guide={false}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="CVV"
                                className={
                                  "w-50 form-control ml-3 mr-3 text-center" +
                                  (errors.cvv && touched.cvv
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                            )}
                          </Field>
                          <ErrorMessage
                            name="card_details.cvv"
                            component="div"
                            className="form_invalid"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-2">
                    <button
                      type="submit"
                      value="REGISTER"
                      className="btn w-100"
                    >
                      UPDATE REGISTRATION
                    </button>
                  </div>
                  <ScrollToFieldError />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};
