import { useEffect } from "react";
import { io } from "socket.io-client";
import { useNavigate } from "react-router-dom";
import { getAuthUser } from "./components/Authentication/authHelper";
import { toast } from "react-hot-toast";
// import axios from "axios";
// import { mainServerAppUrl } from "./apis/mainApi";
import { serverAppUrl } from "./apis/mainApi";



const SocketConnection = () => {
  // const socket = io("https://localhost:9000", { transports: ["websocket"] });
  // const socket = io('https://161.35.31.151', { transports: ["websocket"] });
  const socket = io(serverAppUrl, { transports: ["websocket"] });
  // console.log(serverAppUrl,"<------url")

  const navigate = useNavigate();

  useEffect(() => {
    function connectUser() {
      // Called whenever a user signs in
      let userId = getAuthUser()?.membership_no; // Retrieve userId
      if (!userId) return;
      socket.emit("userConnected", userId);
      console.log("user connected: ", userId);
    }
    // function disconnectUser() {
    //   // Called whenever a user signs out
    //   let userId = getAuthUser()?.membership_no; // Retrieve userId
    //   if (!userId) return;
    //   socket.emit("userDisconnected", userId);
    //   console.log("user connected: ", userId);
    // }

    socket.on("connect", connectUser);
    // socket.on("disconnect", disconnectUser);

    socket.on("newOrder", function (data) {
      // console.log("newOrder", data);
      const existingOrders = JSON.parse(localStorage.getItem("orders")) || [];
      const totalOrders = [...existingOrders, data];
      localStorage.setItem("orders", JSON.stringify(totalOrders));
      navigate("/order-accept-decline", { state: totalOrders });
      
    });
    

    socket.on("acceptOrder", function (data) {
      // console.log("acceptOrder",data);
      toast.success(
        (t) => (
          <div>
            <p>
              <b>Your Order is Confirmed!</b>
              {/* <br /> Order Id:{" "} */}
              {/* <span style={{ fontSize: "15px" }}>{data._id}</span> */}
            </p>
            {/* <br /> */}
            <p>
              <b>Meal Host: </b>
              {data.meal_host}
            </p>
            <p>
              <b>Meal Name: </b>
              {data.meal_name + " (" + data.meal_type + ")"}
            </p>
            <p>
              <b>Meal Price: </b>
              {data.price}
            </p>
            <p>
              <b>Quantity: </b>
              {data.order_quantity}
            </p>
            <p>
              <b>Total Amount: {data.order_quantity * data.price}</b>
            </p>
            <br />
            <input
              style={{
                backgroundColor: "#20400f",
                color: "#fff",
                borderRadius: "5px",
                padding: "3px",
                "&:hover": { color: "black" },
              }}
              type="submit"
              value="Okay"
              className="btn_form w-100"
              onClick={() => {
                toast.dismiss(t.id);
                // navigate("patron-payment")
              }}
            />
            {/* {console.log(t)} */}
          </div>
        ),
        {
          position: "bottom-center",
          duration: Infinity,
          style: {
            borderRadius: "5px",
            // width: "400px",
            // height: "400px",
            fontSize: "1.1rem",
            // scale: '1.5,
            boxShadow: "0 0 11px rgba(33,33,33,.5)",
          },
          // backdrop:false
        }
      );
    });

    socket.on("declineOrder", function (data) {
      // console.log("declineOrder",data);
      toast.error(
        (t) => (
          <div>
            <p>
              <b>Your Order is Declined!</b>
              {/* <br />
              Order Id:{" "}
              <span style={{ fontSize: "15px" }}>{data._id}</span> */}
            </p>
            {/* <br /> */}
            <p>
              <b>Meal Host: </b>
              {data.meal_host}
            </p>
            <p>
              <b>Meal Name: </b>
              {data.meal_name + " (" + data.meal_type + ")"}
            </p>
            <br />
            <input
              style={{
                backgroundColor: "#20400f",
                color: "#fff",
                borderRadius: "5px",
                padding: "3px",
                "&:hover": { color: "black" },
              }}
              type="submit"
              value="OK"
              className="btn_form w-100"
              onClick={() => {
                toast.dismiss(t.id);
                // navigate("patron-payment");
              }}
            />
            {/* {console.log(t)} */}
          </div>
        ),
        {
          position: "bottom-center",
          duration: Infinity,
          style: {
            borderRadius: "5px",
            fontSize: "1.1rem",
            boxShadow: "0 0 11px rgba(33,33,33,.5)",
          },
        }
      );
    });

    socket.on("paid", function (data) {
      toast.success(`Order with invoice id -> ${data.invoiceID} is paid.`, {
        id: "order paid",
        duration: 10000,
      });

      navigate("/mealhost-contact",{state: data.mealhost_id});
    });

    socket.on("failed", function (data) {
      toast.error(`Payment failed for order with invoice id -> ${data}.`, {
        id: "order paid",
        duration: 10000,
      });
      // console.log(`Payment failed for order with invoice id -> ${data}.`);
    });

    socket.on("error", (error) => {
      console.error(error);
    });

    socket.on("orderDeliveredpatron", function (data) {
      toast.success(`Your order is served.`, {
        id: "order delivered",
        duration: 3000,
      });
      // console.log("order delivered socket",data);
      navigate("/patron-ratings",{state: data});
    })

    socket.on("orderDeliveredmealhost", function (data) {
      toast.success(`You served this order.`, {
        id: "order delivered",
        duration: 3000,
      });
      // console.log("order delivered socket",data);
      navigate("/mealhost-ratings",{state: data});
    })


    socket.on("ratingByPatron", function (data) {
      // console.log("ratingByPatron",data);
      navigate("/patron-ratings",{state: data});
    })

    socket.on("ratingByMealhost", function (data) {
      // console.log("ratingByMealhost",data);
      navigate("/mealhost-ratings",{state: data});
    })

    return () => {
      // Unsubscribe from the event on component unmount
      socket.off("acceptOrder");
      socket.off("declineOrder");
      socket.off("newOrder");
      socket.off("paid");
      socket.off("failed");
      socket.off("orderDelivered");
      socket.off("ratingByPatron");
      socket.off("ratingByMealhost");
    };
  }, [navigate, socket]);

  return <></>; // Empty component, can be removed if not needed
};

export default SocketConnection;
