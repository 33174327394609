import React, { useState } from "react";
import { SideMenu } from "../Side-Menu/sideMenu";
import { mainServerAppUrl } from "../../apis/mainApi";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { emailAdminValidation } from "../Validation/ValidationSchema";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const Feedback = () => {
  const [disabled, setDisabled] = useState(false);
  const [role, setRole] = useState(localStorage.getItem('Role'));

  const emailAdmin = (message) => {
    setDisabled(true);
    axios
      .post(mainServerAppUrl + '/user/email-admin', message)
      .then((result) => {
        toast.success(result.data.message, { id: 'mail sent', duration: 3000 });
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.message, { id: 'error', duration: 3000 });
      })
  };

  return (
      
    <div className="max-resolution">
       <div className="home-section-1 title_box">
          {/* <SideMenu /> */}
          <span style={{
            display: 'block',
            fontWeight: '900',
            color: '#fff',
            fontSize: '18px',
            textAlign: 'center'
          }}>{role === 'Patron' ? "PATRON - " : role === 'MealHost' ? "MEAL HOST - " : ""}SEND EMAIL TO ADMIN</span>
        </div>
      
    <Header/>
    <div className="home-section-1 title_box">
    {role === 'Patron' ?
    <h2
    style={{
            display: 'block',
            fontWeight: '900',
            color: '#fff',
            // fontSize: '18px',
            textAlign: 'center'
          }}
          > Easy and Fast Access to Homey-FOODS</h2>: <p>  Use culinary skills offer regional cuisines and become financially successful</p>}
    </div>
      <main style={{ padding: '0rem' }}>
       
        <div className="custom-form centered pb-4">
          <Formik
            enableReinitialize={true}
            validateOnMount={true}
            validateOnChange={true}
            validateOnBlur={true}
            validationSchema={emailAdminValidation}
            initialValues={{
              message: ''
            }}
            onSubmit={(values) => {
              emailAdmin(values)
            }}>
            {({
              errors,
              touched,
            }) => (
              <fieldset>
                <Form>
                  <div className="row">
                    <div className="col-12">
                      <Field
                        component="textarea"
                        name='message'
                        rows="4"
                        cols='50'
                        placeholder="Write your message here, it will be e-mailed to the Admin"
                        maxlength='250'
                        style={{ height: '250px', resize: 'none' }}
                        className={
                          "w-100 form-control" +
                          (errors.message && touched.message
                            ? " is-invalid"
                            : "")
                        }
                      />
                      <ErrorMessage
                        name="message"
                        component="div"
                        className="form_invalid"
                      />
                    </div>
                    <div className="col-12 p-3">
                      <button
                        type="submit"
                        value="REGISTER"
                        className="btn w-100"
                        disabled={disabled}
                      >
                        SEND EMAIL
                      </button>
                    </div>
                  </div>
                </Form>
              </fieldset>
            )}
          </Formik>
        </div>
      </main>
     <Footer/>
    </div>
  );
};
