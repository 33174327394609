import React, { useEffect, useState } from "react";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { FormComponent } from "./formComponent";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
import { getAuthUser } from "../Authentication/authHelper";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const UpdateMeal = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
    getMeal()
  }, []);

  const [displayStates, setDisplayStates] = useState({});
  const [mealData, setMealData] = useState([]);

  const getMeal = () => {
    axios
      .get(mainServerAppUrl + "/get-menu/" + getAuthUser()?._id)
      .then((result) => {
        setMealData(result?.data?.details);
        // console.log("setmealdata",result?.data?.details)
      })
      .catch((error) => {
        console.log(error);
      });
  };

 

  const days = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const handleDayCheckboxClick = (day) => {
    setDisplayStates((prevDisplayStates) => ({
      ...prevDisplayStates,
      [day]: !prevDisplayStates[day]
    }));
  };

  const handleMealCheckboxClick = (day, mealType) => {
    setDisplayStates((prevDisplayStates) => ({
      ...prevDisplayStates,
      [day]: {
        ...prevDisplayStates[day],
        [mealType]: !prevDisplayStates[day]?.[mealType]
      }
    }));
  };

  const renderDay = (day) => {
    const dayDisplayState = displayStates[day] || {};
    const displayBreakfast = dayDisplayState.Breakfast ? "block" : "none";
    const displayLunch = dayDisplayState.Lunch ? "block" : "none";
    const displayDinner = dayDisplayState.Dinner ? "block" : "none";

    return (
      <div key={day} className="col-12">
        {/* Render code for each day */}
        <table className="table">
          <tbody>
            <tr>
              <th scope="col">{day}</th>
              <th scope="col">-</th>
              <th scope="col">
                <input
                  type="checkbox"
                  className="input-checkbox"
                  value={day}
                  onClick={() => handleDayCheckboxClick(day)}
                />
              </th>
            </tr>
          </tbody>
        </table>
        <div
          className="select-day-meal"
          id={day}
          style={{ display: displayStates[day] ? "block" : "none" }}
        >
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text"> Breakfast</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Breakfast`}
                  onClick={() => handleMealCheckboxClick(day, "Breakfast")}
                />
              </div>
              {displayBreakfast === "block" && (
                <FormComponent mealDay={day} mealType={"Breakfast"} display={displayBreakfast} isUpdate={true}  mealData={mealData}/>
              )}
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text">LUNCH</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Lunch`}
                  onClick={() => handleMealCheckboxClick(day, "Lunch")}
                />
              </div>
              {displayLunch === "block" && (
                <FormComponent mealDay={day} mealType={"Lunch"} display={displayLunch} isUpdate={true} mealData={mealData}/>
              )}
            </div>
          </div>
          <div className="row my-2">
            <div className="col-12">
              <div className="d-flex justify-content-between align-items-center add-meal">
                <span className="days_text">DINNER</span>
                <input
                  type="checkbox"
                  className="input-checkbox day-meal"
                  value={`${day}-Dinner`}
                  onClick={() => handleMealCheckboxClick(day, "Dinner")}
                />
              </div>
              {displayDinner === "block" && (
                <FormComponent mealDay={day} mealType={"Dinner"} display={displayDinner} isUpdate={true}
                mealData={mealData} />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="max-resolution">
         <section className="title_box">
          <h2>MEAL HOST UPDATE YOUR MENU</h2>
      </section>
      <Header />
      <section className="title_box">
        <div className="container">
          <p>
            Use culinary skills offer regional cuisines and become financially successful.
          </p>
        </div>
      </section>
      <main className="pb-0 pt-0">
        <div className="main_border pt-4">
          <div className="container">
            <div className="mb-4 select-menu-days menu_content_box row justify-content-center ">
              {days.map((day) => renderDay(day))}
            </div>
          </div>
        </div>
        <div className="menu_center_box py-4">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <p>
                  Above you have option to fill seven Meal Combos for Breakfast,
                  Lunch and Dinner each. You can limit the need to prepare
                  multiple meals daily, you can repeat. So, you will need to
                  prepare only one Meal Combo each for Breakfast, Lunch and
                  Dinner any day of the week.
                </p>
                <h3>YOU CAN DUPLICATE THE COMBO MEALS</h3>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default UpdateMeal;









// below lies the old code


// import React, { useEffect, useState } from "react";
// import { SideMenu } from "../Side-Menu/sideMenu";
// import { FormComponent } from "./formComponent";
// import axios from "axios";
// import { mainServerAppUrl } from "../../apis/mainApi";
// import { getAuthUser } from "../Authentication/authHelper";
// import { Header } from "../Header/header";
// import { Footer } from "../Footer/footer";

// export const UpdateMeal = () => {
//   useEffect(() => {
//     window.scrollTo(0, 0);
//     getMeal();
//   }, []);

//   const [mealData, setMealData] = useState([]);

//   const getMeal = () => {
//     axios
//       .get(mainServerAppUrl + "/get-menu/" + getAuthUser()?._id)
//       .then((result) => {
//         setMealData(result?.data?.details);
//         // console.log("setmealdata",result?.data?.details)
//       })
//       .catch((error) => {
//         console.log(error);
//       });
//   };

//   return (
//     <div className="max-resolution">
//       <Header/>
//       <section className="title_box">
//         <div className="container">
//           <div className="drop-down position-relative">
//             {/* <SideMenu /> */}
//           </div>
//           <p>
//             Use culinary skills offer regional cuisines and become financially
//             successful.{" "}
//           </p>
//           <h2>MEAL HOST UPDATE YOUR MENU</h2>
//         </div>
//       </section>
//       <main className="pb-0 pt-0">
//         <div className="main_border pt-4">
//           <div className="container">
//             <div className="mb-4 select-menu-days menu_content_box row justify-content-center ">
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Monday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           name="meal_day"
//                           value="Monday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Monday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Breakfast"
//                           name="meal_type"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Monday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Monday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Monday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Monday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table ">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Tuesday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Tuesday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Tuesday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Tuesday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Tuesday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Tuesday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Tuesday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Wednesday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Wednesday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Wednesday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Wednesday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Wednesday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Wednesday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Wednesday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Thursday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Thursday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Thursday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Thursday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Thursday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Thursday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Thursday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Friday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Friday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Friday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Friday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Friday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Friday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Friday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Saturday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Saturday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Saturday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Saturday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Saturday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Saturday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Saturday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-12">
//                 <table className="table">
//                   <tbody>
//                     <tr>
//                       <th scope="col">Sunday</th>
//                       <th scope="col">-</th>
//                       <th scope="col">
//                         <input
//                           type="checkbox"
//                           className="input-checkbox"
//                           value="Sunday"
//                         />
//                       </th>
//                     </tr>
//                   </tbody>
//                 </table>
//                 <div className="select-day-meal" id="Sunday">
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text"> Breakfast</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Breakfast"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Sunday"}
//                         mealType={"Breakfast"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">LUNCH</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Lunch"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Sunday"}
//                         mealType={"Lunch"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                   <div className="row my-2">
//                     <div className="col-12">
//                       <div className="d-flex justify-content-between align-items-center add-meal">
//                         <span className="days_text">DINNER</span>
//                         <input
//                           type="checkbox"
//                           className="input-checkbox day-meal"
//                           value="Sunday-Dinner"
//                         />
//                       </div>
//                       <FormComponent
//                         mealDay={"Sunday"}
//                         mealType={"Dinner"}
//                         isUpdate={true}
//                         mealData={mealData}
//                       />
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         <div className="menu_center_box py-4">
//           <div className="container">
//             <div className="row">
//               <div className="col-12 text-center">
//                 <p>
//                   Above you have option to fill seven Meal Combos for Breakfast,
//                   Lunch and Dinner each. You can limit the need to prepare
//                   multiple meals daily, you can repeat. So, you will need to
//                   prepare only one Meal Combo each for Breakfast, Lunch and
//                   Dinner any day of the week.
//                 </p>
//                 <h3>YOU CAN DUPLICATE THE COMBO MEALS</h3>
//               </div>
//             </div>
//             {/* <div className="row">
//               <div className="col-12">
//                 <div className="all-meal-btn">
//                   <input type="submit" />
//                 </div>
//               </div>
//             </div> */}
//           </div>
//         </div>
//       </main>
//       <Footer/>
//       </div>
//   );
// };
