import * as yup from "yup";

const role = localStorage.getItem("Role");
// console.log(role)

export const mealhostRegistrationValidation = yup.object().shape({
  profile: yup
    .string()
    .required(
      "Tell us about yourself and you why want to be meal host in 40 words?"
    )
    .max(200),

  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid First Name"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid Last Name"),
  dob: yup.object().shape({
    day: yup
      .number()
      .required("Day is required")
      .min(1, "Day must be between 1 and 31")
      .max(31, "Day must be between 1 and 31"),
    month: yup
      .number()
      .required("Month is required")
      .min(1, "Month must be between 1 and 12")
      .max(12, "Month must be between 1 and 12"),
    year: yup
      .number()
      .required("Year is required")
      .min(1900, "Year must be after 1900")
      .max(2023, "Year cannot be in the future"),
  }),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
  address_line1: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  address_line2: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^(?!\s+$)/, "Enter your City Name"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  phone: yup
    .number()
    .typeError("You must specify a number")
    .required("Phone number is required")
    .min(11111, "Minimum 5 digits required")
    .max(999999999999999, "Maximum 15 digits allowed"),
  preferences: yup.array().min(1, "At least one check is required"),
  bank_name: yup.string().required("Bank Name is required"),
  account_holder_name: yup
    .string()
    .required("Account holder's Name is required"),
  bank_address: yup.string().required("Bank Address is required"),
  account_no: yup
    .number()
    .typeError("Account Number must contain digits only")
    .required("Account Number is required")
    .max(999999999999, "Maximum 12 digits allowed"),
  routing_no: yup
    .string()
    // .typeError("Routing Number must contain digits only")
    .required("Routing Number is required"),
  // .max(999999999, "Maximum 9 digits allowed"),
  card_details: yup.object().shape({
    card_type: yup.string().required("Card Type is required"),
    card_number: yup
      .number()
      .typeError("Card Number must contain digits only")
      .required("Card Number is required")
      .max(9999999999999999, "Maximum 16 digits allowed"),
    card_expiry: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        "Enter a valid expiration date."
      )
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .required("Expiration date is required"),
    cvv: yup
      .number()
      .typeError("CVV must contain digits only")
      .required("CVV is required")
      .max(9999, "Maximum 4 digits allowed"),
  }),
  zip_code: yup
    .string()
    .required("Zip Code is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  photo: yup.mixed()
  .required('A photo is required'),
});

export const patronRegistrationValidation = yup.object().shape({
  profile: yup
    .string()
    .required(
      "tell us about yourself and why like home cooked meals in 40 words ?"
    )
    .max(200),
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid First Name"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid Last Name"),
    dob: yup.object().shape({
      day: yup
        .number()
        .required("Day is required")
        .min(1, "Day must be between 1 and 31")
        .max(31, "Day must be between 1 and 31"),
      month: yup
        .number()
        .required("Month is required")
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12"),
      year: yup
        .number()
        .required("Year is required")
        .min(1900, "Year must be after 1900")
        .max(2023, "Year cannot be in the future"),
    }),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
  address_line1: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  address_line2: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^(?!\s+$)/, "Enter your City Name"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  phone: yup
    .number()
    .typeError("You must specify a number")
    .required("Phone number is required")
    .min(11111, "Minimum 5 digits required")
    .max(999999999999999, "Maximum 15 digits allowed"),
  preferences: yup.array().min(1, "At least one preference is required"),
  allergies: yup.array().min(1, "At least one check is required"),
  card_details: yup.object().shape({
    card_type: yup.string().required("Card Type is required"),
    card_number: yup
      .number()
      .typeError("Card Number must contain digits only")
      .required("Card Number is required")
      .max(9999999999999999, "Maximum 16 digits allowed"),
    card_expiry: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        "Enter a valid expiration date."
      )
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .required("Expiration date is required"),
    cvv: yup
      .number()
      .typeError("CVV must contain digits only")
      .required("CVV is required")
      .max(9999, "Maximum 4 digits allowed"),
  }),
  zip_code: yup
  .string()
  .required("Zip Code is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
  photo: yup.mixed()
  .required('A photo is required'),
});

export const updateMealhostRegistrationValidation = yup.object().shape({
  profile: yup
    .string()
    .required(
      "Tell us about yourself and you why want to be meal host in 40 words?"
    )
    .max(200),
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid First Name"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid Last Name"),
    dob: yup.object().shape({
      day: yup
        .number()
        .required("Day is required")
        .min(1, "Day must be between 1 and 31")
        .max(31, "Day must be between 1 and 31"),
      month: yup
        .number()
        .required("Month is required")
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12"),
      year: yup
        .number()
        .required("Year is required")
        .min(1900, "Year must be after 1900")
        .max(2023, "Year cannot be in the future"),
    }),
  address_line1: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  address_line2: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^(?!\s+$)/, "Enter your City Name"),
  phone: yup
    .number()
    .typeError("You must specify a number")
    .required("Phone number is required")
    .min(11111, "Minimum 5 digits required")
    .max(999999999999999, "Maximum 15 digits allowed"),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  account_holder_name: yup
    .string()
    .required("Account holder's Name is required"),
  bank_name: yup.string().required("Bank Name is required"),
  bank_address: yup.string().required("Bank Address is required"),
  account_no: yup
    .number()
    .typeError("Account Number must contain digits only")
    .required("Card Number is required")
    .max(999999999999999999, "Maximum 18 digits allowed"),
  routing_no: yup
    .number()
    .typeError("Routing Number must contain digits only")
    .required("Routing Number is required")
    .max(999999999, "Maximum 9 digits allowed"),
  card_details: yup.object().shape({
    card_type: yup.string().required("Card Type is required"),
    card_number: yup
      .number()
      .typeError("Card Number must contain digits only")
      .required("Card Number is required")
      .max(9999999999999999, "Maximum 16 digits allowed"),
    card_expiry: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        "Enter a valid expiration date."
      )
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .required("Expiration date is required"),
    cvv: yup
      .number()
      .typeError("CVV must contain digits only")
      .required("CVV is required")
      .max(9999, "Maximum 4 digits allowed"),
  }),
  zip_code: yup
  .string()
  .required("Zip Code is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
});

export const updatePatronRegistrationValidation = yup.object().shape({
  profile: yup
    .string()
    .required(
      "tell us about yourself and why like home cooked meals in 40 words ?"
    )
    .max(200),
  first_name: yup
    .string()
    .required("First Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid First Name"),
  last_name: yup
    .string()
    .required("Last Name is required")
    .matches(/^[a-zA-Z\\s]*$/, "Enter a valid Last Name"),
    dob: yup.object().shape({
      day: yup
        .number()
        .required("Day is required")
        .min(1, "Day must be between 1 and 31")
        .max(31, "Day must be between 1 and 31"),
      month: yup
        .number()
        .required("Month is required")
        .min(1, "Month must be between 1 and 12")
        .max(12, "Month must be between 1 and 12"),
      year: yup
        .number()
        .required("Year is required")
        .min(1900, "Year must be after 1900")
        .max(2023, "Year cannot be in the future"),
    }),
  address_line1: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  address_line2: yup
    .string()
    .required("Address is required")
    .matches(/^(?!\s+$)/, "Enter your Address"),
  city: yup
    .string()
    .required("City is required")
    .matches(/^(?!\s+$)/, "Enter your City Name"),
  phone: yup
    .number()
    .typeError("You must specify a number")
    .required("Phone number is required")
    .min(11111, "Minimum 5 digits required")
    .max(999999999999999, "Maximum 15 digits allowed"),
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  preferences: yup.array().min(1, "At least one preference is required"),
  allergies: yup.array().min(1, "At least one preference is required"),
  card_details: yup.object().shape({
    card_type: yup.string().required("Card Type is required"),
    card_number: yup
      .number()
      .typeError("Card Number must contain digits only")
      .required("Card Number is required")
      .max(9999999999999999, "Maximum 16 digits allowed"),
    card_expiry: yup
      .string()
      .matches(
        /^(0[1-9]|1[0-2])\/?([0-9]{2})$/,
        "Enter a valid expiration date."
      )
      .typeError("Not a valid expiration date. Example: MM/YY")
      .max(5, "Not a valid expiration date. Example: MM/YY")
      .required("Expiration date is required"),
    cvv: yup
      .number()
      .typeError("CVV must contain digits only")
      .required("CVV is required")
      .max(9999, "Maximum 4 digits allowed"),
  }),
  zip_code: yup
  .string()
  .required("Zip Code is required"),
  country: yup.string().required("Country is required"),
  state: yup.string().required("State is required"),
});

export const passwordValidation = yup.object().shape({
  oldPassword: yup.string().required("Old Password is Required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const loginValidation = yup.object().shape({
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
  password: yup.string().required("Password is required"),
});

export const forgotPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .email("Email is invalid")
    .required("Email is required")
    .matches(/^(?!\s+$)/, "Enter your Email"),
});

export const resetPasswordValidation = yup.object().shape({
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be 8 characters long")
    .matches(/[0-9]/, "Password requires a number")
    .matches(/[a-z]/, "Password requires a lowercase letter")
    .matches(/[A-Z]/, "Password requires an uppercase letter")
    .matches(/[^\w]/, "Password requires a special character"),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const mealHostHomeValidation = yup.object().shape({
  menu: yup.array().of(
    yup.object().shape({
      preferences: yup.array().min(1, "At least one option is required"),
      meal_name: yup
        .string()
        .required("Meal Name is required")
        .matches(/^[A-Za-z]( ?[A-Za-z] ?)*$/g, "Enter a valid Meal Name"),
      description: yup
        .string()
        .required("Description is required")
        .matches(/^[A-Za-z]( ?[A-Za-z] ?)*$/g, "Enter a valid Description"),
      price: yup
        .number()
        .required("Price is required")
        .min(1, "Enter valid price")
        .max(999, "Price too high"),
      image: yup.mixed().required("Image is required"),
    })
  ),
});

export const emailAdminValidation = yup.object().shape({
  message: yup
    .string()
    .required("Message is required")
    .max(250, "Only 250 characters are allowed")
    .matches(/^[^\s].*$/, "Enter valid message"),
});
