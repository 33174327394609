import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function TransitionsModal(props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("_token");
    localStorage.removeItem("Role");
    navigate("/");
  };

  return (
    <div>
      <Button onClick={handleOpen}>Logout</Button>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              {props.logoutTitle}
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              <Button
                variant="outlined"
                color="error"
                onClick={logout}
                style={{ float: "right" }}
              >
                Logout
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                style={{ float: "right", marginRight: "8px" }}
              >
                Cancel
              </Button>
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
