import React, { useEffect, useState } from "react";
// import { SideMenu } from "../Side-Menu/sideMenu";
// import { Navigate, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { mainServerAppUrl } from "../../apis/mainApi";
import { getAuthUser } from "../Authentication/authHelper";
import { toast } from "react-hot-toast";
import { Pagination } from "../Pagination/pagination";
import { Footer } from "../Footer/footer";
import { Header } from "../Header/header";
import { useNavigate } from "react-router-dom";
import { Rating } from "react-simple-star-rating";

export const PendingOrder = () => {

    const weekday = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

    const [mealHostId, setMealHostId] = useState(getAuthUser().membership_no);
    const [pendingOrders, setPendingOrders] = useState([]);
    // const accept = "accept";
    // const decline = "decline";
    // const location = useLocation();
    const navigate = useNavigate();
    const [paginatedData, setPaginatedData] = useState([]);

    const handleCurrentRecords = (currentRecords) => {
        setPaginatedData(currentRecords);
        // console.log("currentRecords",currentRecords)
    };

    localStorage.removeItem('orders');

    useEffect(() => {
        axios
            .get(mainServerAppUrl + `/user/mealhost/pending-orders/${mealHostId}`)
            .then((result) => {
                setPendingOrders(result.data.pendingOrders);
                // console.log(result.data.pendingOrders)
            })
            .catch((error) => {
                console.log(error);
            })
    }, [mealHostId])


    const handleSubmit = (orderId,order, status) => {
        // console.log(orderId, status);
        axios
            .post(mainServerAppUrl + `/orders/${status}/${orderId}`)
            .then((result) => {
                if (status === 'accept') {
                    payment(orderId,order);
                }
                const filteredArray = pendingOrders.filter(
                    (orders) => orders._id !== orderId
                );
                setPendingOrders([...filteredArray]);
                status === "accept" ?
                    toast.success(`Order Accepted\nOrder Name: ${result.data.meal_name}\nOrder Type: ${result.data.meal_type}\nQty:${result.data.order_quantity}`, { id: "order confirmation", duration: 5000 }) :
                    toast.error(`Order Declined\nOrder id: ${orderId}\nOrder Name: ${result.data.meal_name}`, { id: "order declined", duration: 5000 })
            })
            .catch((error) => {
                console.log(error)
                toast.error(error.response.data.message, {
                    id: "error",
                    duration: 3000,
                });
            });
    };

    const payment = (orderId,order) => {
        axios
            .post(mainServerAppUrl + '/patron/payment/invoice', { orderId })
            .then((result) => {
                toast.dismiss('order confirmation');
                toast.success(result.data.message, { id: 'invoice', duration: 3000 });
                navigate("/patron-contact", { state : order });

            })
            .catch((error) => {
                console.log(error);
                toast.dismiss('order confirmation');
                toast.error(error.response.data.message, { id: 'error', duration: 3000 });
            })
    };

    return (
      <div className="max-resolution">
        <section className="title_box">
          <div className="container position-relative">
            <h2>MEAL HOST- PENDING ORDERS</h2>
          </div>
        </section>
        <Header />
        <section className="title_box">
        <div className="container position-relative">
          {/* <SideMenu /> */}
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
        <main>
          <div className="container">
            {pendingOrders.length === 0 ? (
              <p
                style={{
                  fontSize: "22px",
                  fontWeight: "600",
                  textAlign: "center",
                }}
              >
                No pending orders.
              </p>
            ) : (
              <div className="custom-form centered mb-4">
                <form onSubmit={handleSubmit}>
                  {paginatedData &&
                    paginatedData.map((orders, i) => (
                      <div key={i}>
                        <div className="green_box_history meal-order mt-4">
                          <div className="row py-3">
                            <div className="col-12 text-center">
                              <p>{orders.patron}</p>
                              {/* {console.log(orders)} */}
                              <Rating
                                initialValue={orders?.ratings}
                                readonly
                                allowFraction
                                size={"23"}
                              />
                              <span style={{ fontSize: "15px" }}>
                                {"(" + orders.num_of_reviews + ")"}
                              </span>
                            </div>
                          </div>
                          <div className="row py-3">
                            {/* {console.log(orders)} */}
                            <div className="col-3">
                              <img
                                src={
                                  `${process.env.REACT_APP_BACKEND_URL}/uploads/` +
                                  orders?.imageUrl
                                }
                                alt={
                                  orders?.imageUrl
                                    ? orders?.imageUrl
                                    : "no image"
                                }
                              />
                            </div>
                            <div className="col-6">
                              <p>
                                {weekday[new Date(orders.ordered_On).getDay()]}
                              </p>
                              <small>{orders?.meal_type}</small>
                              <br />
                              <small>{orders?.meal_name} </small>
                              <br />
                              <small style={{ color: "black" }}>
                                Quantity :
                              </small>
                            </div>
                            <div className="col-3">
                              <p>${orders?.price}</p>
                              <br />
                              <br />
                              <p
                                style={{ fontSize: "15px", fontWeight: "bold" }}
                              >
                                {orders?.order_quantity}
                              </p>
                            </div>
                          </div>
                          <div className="row py-3">
                            <div className="col-9">
                              <p>Total</p>
                            </div>
                            <div className="col-3">
                              <p>${orders?.price * orders?.order_quantity}</p>
                            </div>
                          </div>
                        </div>
                        <div className="row mt-4">
                          <div className="col-6">
                            <input
                              type="submit"
                              value="ACCEPT"
                              className="btn_form w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(orders._id,orders, "accept");
                              }}
                            />
                          </div>
                          <div className="col-6">
                            <input
                              type="submit"
                              value="DECLINE"
                              className="btn_form bg-pink w-100"
                              onClick={(e) => {
                                e.preventDefault();
                                handleSubmit(orders._id,orders, "decline");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </form>
              </div>
            )}
          </div>
          <Pagination
            data={pendingOrders}
            handleCurrentRecords={handleCurrentRecords}
          />
        </main>
        <Footer />
      </div>
    );
};


















// old UI code lies below

// import React, { useEffect, useState } from "react";
// // import { SideMenu } from "../Side-Menu/sideMenu";
// // import { Navigate, useLocation, useNavigate } from "react-router-dom";
// import axios from "axios";
// import { mainServerAppUrl } from "../../apis/mainApi";
// import { getAuthUser } from "../Authentication/authHelper";
// import { toast } from "react-hot-toast";
// import { Pagination } from "../Pagination/pagination";
// import { Footer } from "../Footer/footer";
// import { Header } from "../Header/header";
// import { useNavigate } from "react-router-dom";
// import { Rating } from "@mui/material";

// export const PendingOrder = () => {

//     const [mealHostId, setMealHostId] = useState(getAuthUser().membership_no);
//     const [pendingOrders, setPendingOrders] = useState([]);
//     const accept = "accept";
//     const decline = "decline";
//     // const location = useLocation();
//     const navigate = useNavigate();
//     const [paginatedData, setPaginatedData] = useState([]);

//     const handleCurrentRecords = (currentRecords) => {
//         setPaginatedData(currentRecords);
//         // console.log("currentRecords",currentRecords)
//     };

//     localStorage.removeItem('orders');

//     useEffect(() => {
//         axios
//             .get(mainServerAppUrl + `/user/mealhost/pending-orders/${mealHostId}`)
//             .then((result) => {
//                 setPendingOrders(result.data.pendingOrders);
//                 // console.log(result.data.pendingOrders)
//             })
//             .catch((error) => {
//                 console.log(error);
//             })
//     }, [mealHostId])

//     const handleSubmit = (orderId, status) => {
//         // console.log(orderId, status);
//         axios
//             .post(mainServerAppUrl + `/orders/${status}/${orderId}`)
//             .then((result) => {
//                 if (status === 'accept') {
//                     payment(orderId);
//                 }
//                 const filteredArray = pendingOrders.filter(
//                     (orders) => orders._id !== orderId
//                 );
//                 setPendingOrders([...filteredArray]);
//                 status === "accept" ?
//                     toast.success(`Order Accepted\nOrder Id: ${orderId}.\nOrder Name: ${result.data.meal_name}`, { id: "order confirmation", duration: 5000 }) :
//                     toast.error(`Order Declined\nOrder id: ${orderId}\nOrder Name: ${result.data.meal_name}`, { id: "order declined", duration: 5000 })
//             })
//             .catch((error) => {
//                 console.log(error)
//                 toast.error(error.response.data.message, {
//                     id: "error",
//                     duration: 3000,
//                 });
//             });
//     };

//     const payment = (orderId) => {
//         axios
//             .post(mainServerAppUrl + '/patron/payment/invoice', { orderId })
//             .then((result) => {
//                 toast.dismiss('order confirmation');
//                 toast.success(result.data.message, { id: 'invoice', duration: 3000 });
//                 navigate("/patron-contact", { state : orderId });

//             })
//             .catch((error) => {
//                 console.log(error);
//                 toast.dismiss('order confirmation');
//                 toast.error(error.response.data.message, { id: 'error', duration: 3000 });
//             })
//     };


//     return (

        // <div className="max-resolution">
        //     <Header/>
        //     <section className="title_box">
        //         <div className="container position-relative">
        //             {/* <SideMenu /> */}
        //             <h2>MEAL HOST- PENDING ORDERS</h2>
        //         </div>
        //     </section>
        //     <main>
        //         <div className="container">
        //             {pendingOrders.length === 0 ?
        //                 <p style={{ fontSize: '22px', fontWeight: '600', textAlign: 'center' }} >Seems like you don't have any pending orders yet.</p>
        //                 :
        //                 <div className="custom-form centered mb-4">
        //                     <form onSubmit={handleSubmit}>
        //                         {paginatedData && paginatedData.map((orders, ind) => (
        //                             <div className="green_box_history meal-order mt-4" key={ind}>
        //                                 <div className="row py-3">
        //                                     <div className="col-5">
        //                                         <p>Pending Order</p>
        //                                     </div>
        //                                     <div className="col-7">
        //                                         <p style={{ textAlign: "end" }}>{orders.meal_name}</p>
        //                                     </div>
        //                                 </div>
        //                                 <div className="row py-3">
        //                                     <div className="col-5">
        //                                         <p>Ordered By</p>
        //                                     </div>
        //                                     <div className="col-7">
        //                                         <p style={{ textAlign: "end" }}>{orders.patron}</p>
        //                                     </div>
        //                                 </div>
        //                                 <div className="row py-3">
        //                                     <div className="col-8">
        //                                         <p>Order Quantity</p>
        //                                     </div>
        //                                     <div className="col-4">
        //                                         <p style={{ textAlign: "center" }}>{orders.order_quantity}</p>
        //                                     </div>
        //                                 </div>
        //                                 <div className="row py-3">
        //                                     <div className="col-8">
        //                                         <p>Total</p>
        //                                     </div>
        //                                     <div className="col-4">
        //                                         <p style={{ textAlign: "center" }}>${orders.order_quantity * orders.price}</p>
        //                                     </div>
        //                                 </div>
        //                                 <div className="row py-3">
        //                                     <div className="col-8">
        //                                         <p>ID</p>
        //                                     </div>
        //                                     <div className="col-4">
        //                                         <p style={{ float: 'right' }}>${orders.invoiceID}</p>
        //                                     </div>
        //                                 </div>
        //                                 <div className="row mt-4">
        //                                     <div className="col-6">
        //                                         <button
        //                                             className="btn_form w-100"
        //                                             onClick={(e) => {
        //                                                 e.preventDefault();
        //                                                 handleSubmit(orders._id, accept);
        //                                             }}
        //                                         >
        //                                             ACCEPT
        //                                         </button>
        //                                     </div>
        //                                     <div className="col-6">
        //                                         <button
        //                                             className="btn_form w-100"
        //                                             onClick={(e) => {
        //                                                 e.preventDefault();
        //                                                 handleSubmit(orders._id, decline);
        //                                             }}
        //                                         >
        //                                             DECLINE
        //                                         </button>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         ))}
        //                     </form>
        //                 </div>
        //             }
        //         </div>
        //        <Pagination data={pendingOrders} handleCurrentRecords={handleCurrentRecords}/>
        //     </main>
        //     <Footer/>
        // </div>
//     );
// };
