import React, { useEffect } from "react";
import { getAuthUser } from "../Authentication/authHelper";
import moment from "moment";
import { useState } from "react";
import { mainServerAppUrl } from "../../apis/mainApi";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
// import { SideMenu } from "../Side-Menu/sideMenu";
import { toast } from "react-hot-toast";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const MealHostRatings = () => {
  const [rating, setRating] = useState(0);
  const [isActive, setIsActive] = useState(false);
  // const [patron, setPatron] = useState([]);
  // const [patronId, setPatronId] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("LOCATION-->",location)


  let firstName = getAuthUser()?.first_name;
  let lastName = getAuthUser()?.last_name;
  let mealhostId = getAuthUser()?.membership_no;

  // useEffect(() => {
  //   axios
  //     .post(mainServerAppUrl + "/user/get-patron", { mealhostId: mealhostId })
  //     .then((result) => {
  //       setPatron(result.data);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // }, []);

  const submitRating = (e) => {
    e.preventDefault();

    const data = {
      rating: Number(rating),
      name: firstName + lastName,
      mealhost_id: mealhostId,
      // patron_id: patronId,
      patron_id: location?.state?.patron_id,
      order_id: location?.state?._id,
    };

    // console.log(data);

    if (data.rating) {
    axios
      .post(mainServerAppUrl + "/user/mealhost/ratings", data)
      .then((result) => {
        // console.log(result);
        toast.success(result.data.message, {
          id: "rating success",
          duration: 2000,
        });
        setTimeout(() => {
          window.location.href = "/mealhost-home";
        }, 2000);
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status === 404) {
          toast.error(error.response.data.message, {
            id: "not found",
            duration: 3000,
          });
          // setTimeout(() => {
          //   navigate("/patron-not-found");
          // }, 4000);
        } else {
          toast.error(error.response.data.message, {
            id: "error",
            duration: 3000,
          });
        }
      });
    } else {
      toast.error("Please select a star", {
        id: "error",
        duration: 3000,
      });
    };
  };

  const handleClick = (e) => {
    setRating(e.currentTarget.getAttribute("value"));
    setIsActive(e.currentTarget.getAttribute("value"));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="max-resolution">
       <section className="title_box">
          <h2>RATE YOUR PATRON</h2>
      </section>
      <Header/>
      <section className="title_box">
        <div className="container position-relative">
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="custom-form centered mb-4">
            <form action="meal_history_served.html" onSubmit={submitRating}>
              <div className="row">
                <div className="col-12">
                {/* <select
                  className="w-100 form-control pt-0"
                  onChange={(e) => {
                    setPatronId(e.target.value);
                  }}
                  defaultValue={"DEFAULT"}
                >
                  <option disabled key="DEFAULT" value="DEFAULT">
                    Select Patron
                  </option>
                  {patron &&
                    patron.map((user, i) => (
                      <option value={user.patron_id} key={i}>
                        {user.patron + " (" + user.patron_id + ")"}
                      </option>
                    ))}
                </select> */}

                  <input
                    type="text"
                    name="host_number"
                    placeholder="Enter Patron's Number"
                    // value={"Your Patron's Number - " + location?.state?.patronId}
                    value={location?.state?.patron + " ("+location?.state?.patron_id+")"}
                    className="w-100 form-control pt-0"
                    readOnly
                  />

                </div>
                <div className="col-12">
                  <input
                    type="date"
                    name="date"
                    placeholder="Date "
                    value={moment(new Date(location?.state?.ordered_On)).format("YYYY-MM-DD")}
                    className="w-100 form-control"
                    readOnly
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="first_name"
                    placeholder="First Name"
                    value={firstName}
                    className="w-100 form-control"
                    readOnly
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    name="last_name"
                    placeholder="Last Name"
                    value={lastName}
                    className="w-100 form-control"
                    readOnly
                  />
                </div>
                <div className="col-12">
                  <div className="rating_section">
                    <span
                      className="rating_star"
                      onClick={handleClick}
                      value={1}
                      style={{
                        scale: isActive == 1 ? "1.2" : "",
                        color: isActive == 1 ? "white" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span className="rating_text">Poor</span>
                    </span>
                    <span
                      className="rating_star"
                      onClick={handleClick}
                      value={2}
                      style={{
                        scale: isActive == 2 ? "1.2" : "",
                        color: isActive == 2 ? "white" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span className="rating_text">Acceptable</span>
                    </span>
                    <span
                      className="rating_star"
                      onClick={handleClick}
                      value={3}
                      style={{
                        scale: isActive == 3 ? "1.2" : "",
                        color: isActive == 3 ? "white" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span className="rating_text">Good</span>
                    </span>
                    <span
                      className="rating_star"
                      onClick={handleClick}
                      value={4}
                      style={{
                        scale: isActive == 4 ? "1.2" : "",
                        color: isActive == 4 ? "white" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span className="rating_text">Very Good</span>
                    </span>
                    <span
                      className="rating_star"
                      onClick={handleClick}
                      value={5}
                      style={{
                        scale: isActive == 5 ? "1.2" : "",
                        color: isActive == 5 ? "white" : "",
                        cursor: "pointer",
                      }}
                    >
                      <span className="rating_text">Excellent</span>
                    </span>
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="submit"
                    value="SUBMIT"
                    className="btn_form w-100"
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
