import React, { useState, useEffect } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import axios from "axios";
import Visa from "../../assets/images/visa-card.svg";
import MasterCard from "../../assets/images/mastercard.svg";
import Amex from "../../assets/images/amex.svg";
import { mealhostRegistrationValidation } from "../Validation/ValidationSchema";
import { mainServerAppUrl } from "../../apis/mainApi";
import { SideMenu } from "../Side-Menu/sideMenu";
import moment from "moment";
import { toast } from "react-hot-toast";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";
import { CountryCodes } from "../../common/countryCodes";
import { ScrollToFieldError } from "../Validation/scrollToFieldError";
import MaskedInput from "react-text-mask";
import {
  accountNumberMask,
  cardNumberMask,
  routingNumberMask,
} from "../Validation/cardNumberMask";
import jsPDF from "jspdf";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";
import { getAuthUser } from "../Authentication/authHelper";

export default function MealHostRegistrationForm() {
  const [membershipNo, setMembershipNo] = useState("");
  const [membershipDiv, setMembershipDiv] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const path = location.search;
  const sessionId = path.split("=")[1];
  const email = localStorage.getItem("email");
  const password = localStorage.getItem("password");
  const data = { email, password };
  const [showLink, setShowLink] = useState(true);
  const [previewImage, setPreviewImage] = useState(null);


  useEffect(() => {
    window.scrollTo(0, 0);
    localStorage.setItem("Role", "MealHost");
  }, []);

  useEffect(() => {
    axios
      .get(mainServerAppUrl + `/payment-success/${sessionId}`)
      .then((result) => {
        // setCustomerSession(result.data.session);
        console.log("result after payment succeess",result)
        setTimeout(()=>{
          loginUser(data);
        },2000)
        toast.success("Meal Host Registration Successful", {
          id: "success",
          duration: 3000,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const navigate = useNavigate();

  const handleImageUpload = (e,setFieldValue,values) => {
    const file = e.target.files[0];
    console.log("file................",file)
    if (file) {

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
        setFieldValue("image",reader.result)
        console.log("reader.result----->>>>",reader.result)
      };
      reader.readAsDataURL(file);
    }
  };

  const getMembershipNo = (email) => {
    axios
      .get(`${mainServerAppUrl}/user/${email}`)
      .then((data) => {
        // console.log(data);
        setMembershipNo(data?.data?.details[0]?.membership_no);
        console.log("membership no", data?.data?.details[0]?.membership_no)
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loginUser = (data) => {
    axios
      .post(mainServerAppUrl + "/user/login", {
        email: data?.email,
        password: data?.password,
      })
      .then((result) => {
        console.log(result);
        localStorage.setItem("_token", result?.data?.token);
        setIsSubmitted(true);
        getMembershipNo(data.email);
        setMembershipDiv(true);
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const saveAsPDF = (data, membershipNumber) => {
    const pdf = new jsPDF();

    pdf.setFontSize(12);
    pdf.setTextColor(0, 0, 0);

    let x = 10;
    let y = 10;

    pdf.text("Membership Number" + ": " + membershipNumber, x, y);

    y += 10;

    Object.keys(data).forEach((field, index) => {
      if (field === "role") {
        return; // Skip the "Role" field
      }
      const value = data[field];

      // Format the field name and value
      const formattedField = field
        .replace(/_/g, " ")
        .replace(/^\w/, (c) => c.toUpperCase());
      const formattedValue = formatValue(value);

      pdf.text(formattedField + ": " + formattedValue, x, y);

      y += 10;
    });

    pdf.save("Homey-FOODS Meal Host Registration Details.pdf");
  };

  // Helper function to format the value
  const formatValue = (value) => {
    if (typeof value === "object") {
      if (Array.isArray(value)) {
        return value.map((item) => item.toString()).join(", ");
      } else {
        let formattedObject = "";
        for (const prop in value) {
          if (value.hasOwnProperty(prop)) {
            const formattedProp = prop
              .replace(/_/g, " ")
              .replace(/^\w/, (c) => c.toUpperCase());
            const formattedPair = `${formattedProp}: ${value[prop]}\n`;
            formattedObject += formattedPair;
          }
        }
        return formattedObject;
      }
    } else {
      return value.toString();
    }
  };

  const createCheckoutSession = (data) => {
    // setDisabled(true);
    axios
      .post(mainServerAppUrl + "/mealhost/create-checkout-session", {
        email: data.email,
        _id: data._id,
      })
      .then((result) => {
        let url = "";
        url = result.data.url;
        toast.success(result.data.message, { id: "success", duration: 2500 });
        setTimeout(() => {
          window.open(url, "_self");

          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response);
        toast.error(error.response.data.message, {
          id: "error",
          duration: 3000,
        });
      });
  };

  // console.log(getAuthUser())

  const WORDS_LIMIT = 40;

  // Custom validation function to check the number of words
  const validateProfile = (value) => {
    const words = value.trim().split(/\s+/);
    if (words.length > WORDS_LIMIT) {
      return `Please limit your profile to ${WORDS_LIMIT} words or less.`;
    }
  };

  return (
    <div className="max-resolution">
      <section className="title_box">
        <div className="container position-relative">
          {/* {membershipNo && <SideMenu />} */}
          <h2>
            {membershipNo
              ? "MEAL HOST REGISTRATION SUCCESSFUL"
              : "WELCOME MEALHOST - PLEASE REGISTER"}
          </h2>
          {membershipDiv && (
            <h2>
              You have successfully registered. Your membership number is -
              {membershipNo}.
              <br />
              Date: {moment(new Date()).format("Do MMM YYYY")}
            </h2>
          )}
        </div>
      </section>
      <Header />
      <section className="title_box">
        <div className="container position-relative">
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="custom-form centered pb-4">
            <Formik
              enableReinitialize={true}
              validateOnMount={true}
              validateOnChange={true}
              validateOnBlur={true}
              validationSchema={mealhostRegistrationValidation}
              initialValues={{
                registration_date: moment(new Date()).format("YYYY-MM-DD"),
                profile: "",
                first_name: "",
                last_name: "",
                dob: {
                  day: "",
                  month: "",
                  year: ""
                },
                email: "",
                address_line1: "",
                address_line2: "",
                city: "",
                password: "",
                phone: "",
                country: "",
                state: "",
                zip_code: "",
                country_code: "93",
                account_holder_name: "",
                bank_name: "",
                bank_address: "",
                ssn_last_4:"",
                account_no: "",
                routing_no: "",
                card_details: {
                  card_type: "",
                  card_number: "",
                  card_expiry: "",
                  cvv: "",
                },
                role: "MealHost",
                photo: null,
              }}
              onSubmit={(values) => {
                axios
                  .post(mainServerAppUrl + "/user/register", values)
                  .then((data) => {
                    // console.log(data);
                    if (data.status === 201) {
                      // loginUser(values);
                      createCheckoutSession(data?.data);
                      // setTimeout(() => {
                      // navigate("/mealhost-payment");
                      // }, 6000);
                      saveAsPDF(values, data?.data?.membership_no);

                      localStorage.setItem("email", data.data.email);
                      localStorage.setItem("password", values.password);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                    toast.error(err?.response?.data?.message, {
                      id: "failure",
                      duration: 3000,
                    });
                  });
              }}
            >
              {({
                errors,
                status,
                touched,
                values,
                handleChange,
                handleBlur,
                setFieldValue,
              }) => (
                <Form>
                  <fieldset disabled={isSubmitted}>
                    <div className="row">
                      <div className="col-12">
                        <Field
                          type="date"
                          name="registration_date"
                          placeholder="Date of Registration"
                          className={
                            "w-100 form-control" +
                            (errors.city && touched.city ? " is-invalid" : "")
                          }
                          disabled
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="first_name"
                          placeholder="First Name"
                          className={
                            "w-100 form-control" +
                            (errors.first_name && touched.first_name
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().first_name
                              : values.first_name
                          }
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="last_name"
                          placeholder="Last Name"
                          className={
                            "w-100 form-control" +
                            (errors.last_name && touched.last_name
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().last_name
                              : values.last_name
                          }
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <br/>
                        <b>Date of birth</b>
                      </div>
                      <div className="col-4">
      <Field
        type="number"
        name="dob.day"
        placeholder="Day"
        className={
          "w-100 form-control" +
          (errors.dob?.day && touched.dob?.day ? " is-invalid" : "")
        }
        value={
          membershipNo
            ? getAuthUser().dob?.day
            : values.dob?.day
        }
      />
      <ErrorMessage name="dob.day" component="div" className="invalid-feedback" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.month"
        placeholder="Month"
        className={
          "w-100 form-control" +
          (errors.dob?.month && touched.dob?.month ? " is-invalid" : "")
        }
        value={
          membershipNo
            ? getAuthUser().dob?.month
            : values.dob?.month
        }
      />
      <ErrorMessage name="dob.month" component="div" className="invalid-feedback" />
    </div>
    <div className="col-4">
      <Field
        type="number"
        name="dob.year"
        placeholder="Year"
        className={
          "w-100 form-control" +
          (errors.dob?.year && touched.dob?.year ? " is-invalid" : "")
        }
        value={
          membershipNo
            ? getAuthUser().dob?.year
            : values.dob?.year
        }
      />
      <ErrorMessage name="dob.year" component="div" className="invalid-feedback" />
    </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="address_line1"
                          placeholder="Address line 1"
                          className={
                            "w-100 form-control" +
                            (errors.address_line1 && touched.address_line1
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().address_line1
                              : values.address_line1
                          }
                        />
                        <ErrorMessage
                          name="address_line1"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="address_line2"
                          placeholder="Address line 2"
                          className={
                            "w-100 form-control" +
                            (errors.address_line2 && touched.address_line2
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().address_line2
                              : values.address_line2
                          }
                        />
                        <ErrorMessage
                          name="address_line2"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="city"
                          placeholder="City"
                          className={
                            "w-100 form-control" +
                            (errors.city && touched.city
                              ? " is-invalid"
                              : values.city)
                          }
                          value={
                            membershipNo ? getAuthUser().city : values.city
                          }
                        />
                        <ErrorMessage
                          name="city"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <div className="select-wrapper">
                          <CountryDropdown
                            name="country"
                            value={
                              membershipNo
                                ? getAuthUser().country
                                : values.country
                            }
                            onChange={(_, e) => handleChange(e)}
                            onBlur={handleBlur}
                            className={
                              "w-100 form-control" +
                              (errors.country && touched.country
                                ? " is-invalid"
                                : "")
                            }
                            defaultOptionLabel="Country"
                          />
                          <span className="select_arrow"></span>
                          <ErrorMessage
                            name="country"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="select-wrapper">
                          <RegionDropdown
                            name="state"
                            country={values.country ? values.country : "State"}
                            value={
                              membershipNo ? getAuthUser().state : values.state
                            }
                            onChange={(_, e) => handleChange(e)}
                            onBlur={handleBlur}
                            className={
                              "w-100 form-control" +
                              (errors.state && touched.state
                                ? " is-invalid"
                                : "")
                            }
                            defaultOptionLabel="State"
                          />
                          <span className="select_arrow"></span>
                          <ErrorMessage
                            name="state"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="zip_code"
                          placeholder="Zip Code"
                          className={
                            "w-100 form-control" +
                            (errors.zip_code && touched.zip_code
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().zip_code
                              : values.zip_code
                          }
                        />
                        <ErrorMessage
                          name="zip_code"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <div className="row">
                          <div className="col-4">
                            <div className="select-wrapper">
                              <Field
                                as="select"
                                name="country_code"
                                className="w-100 form-control"
                              >
                                {CountryCodes.map((item) => (
                                  <option key={item.iso} value={item.code}>
                                    +{item.code}
                                  </option>
                                ))}
                              </Field>
                              <span className="select_arrow"></span>
                            </div>
                          </div>
                          <div className="col-8">
                            <Field
                              type="number"
                              name="phone"
                              placeholder="Phone"
                              className={
                                "w-100 form-control" +
                                (errors.phone && touched.phone
                                  ? " is-invalid"
                                  : "")
                              }
                              value={
                                membershipNo
                                  ? getAuthUser().phone
                                  : values.phone
                              }
                            />
                            <ErrorMessage
                              name="phone"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                        </div>
                      </div>
                      
                      <div className="col-12">
                      <Field name="photo">
                        {({ field }) => (
                          <div>
                             <br />
                        <b>
                        Please upload your recent photo
                        </b>
                            <input
                              {...field}
                              type="file"
                              onChange={(e) => {
                                handleImageUpload(e,setFieldValue,values);
                                field.onChange(e); // Formik's onChange
                                
                              }}
                              onBlur={handleBlur}
                              accept="image/*"
                              style={{display:"block"}}
                              className={
                                "w-100 form-control" +
                                (errors.photo && touched.photo
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            {previewImage && (
                              <div className="image-preview pt-2">
                                <img
                                  src={previewImage}
                                  alt="Image Preview"
                                  style={{ maxWidth: "40%", height: "auto", margin:"auto" }}
                                />
                              </div>
                            )}
                            <ErrorMessage
                              name="photo"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        )}
                      </Field>
                    </div>

                      <div className="col-12">
                        <Field name="profile" validate={validateProfile}>
                          {({ field }) => (
                            <div>
                              <input
                                {...field}
                                type="text"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Profile: About yourself in 40 words or less"
                                className={
                                  "w-100 form-control" +
                                  (errors.profile && touched.profile
                                    ? " is-invalid"
                                    : "")
                                }
                                value={
                                  membershipNo
                                    ? getAuthUser()?.profile
                                    : field.value
                                }
                                maxLength={200}
                              />
                              <ErrorMessage
                                name="profile"
                                component="div"
                                className="form_invalid"
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="email"
                          placeholder="Email"
                          className={
                            "w-100 form-control" +
                            (errors.email && touched.email ? " is-invalid" : "")
                          }
                          value={
                            membershipNo ? getAuthUser()?.email : values.email
                          }
                        />
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          placeholder="Password"
                          className={
                            "w-100 form-control" +
                            (errors.password && touched.password
                              ? " is-invalid"
                              : "")
                          }
                          value={membershipNo ? password : values.password}
                        />
                        <div
                          className="p-viewer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <i
                            className={
                              showPassword
                                ? "fa-solid fa-eye"
                                : "fa-sharp fa-solid fa-eye-slash"
                            }
                          ></i>
                        </div>
                        <ErrorMessage
                          name="password"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <br />
                        <b>
                          Your Bank Information for wiring payments for meals
                          served by you.
                        </b>
                        <Field
                          type="text"
                          name="account_holder_name"
                          placeholder="Name of Account holder"
                          className={
                            "w-100 form-control" +
                            (errors.account_holder_name &&
                            touched.account_holder_name
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().account_holder_name
                              : values.account_holder_name
                          }
                        />
                        <ErrorMessage
                          name="account_holder_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="bank_name"
                          placeholder="Bank Name"
                          className={
                            "w-100 form-control" +
                            (errors.bank_name && touched.bank_name
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().bank_name
                              : values.bank_name
                          }
                        />
                        <ErrorMessage
                          name="bank_name"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field
                          type="text"
                          name="bank_address"
                          placeholder="Bank Address"
                          className={
                            "w-100 form-control" +
                            (errors.bank_address && touched.bank_address
                              ? " is-invalid"
                              : "")
                          }
                          value={
                            membershipNo
                              ? getAuthUser().bank_address
                              : values.bank_address
                          }
                        />
                        <ErrorMessage
                          name="bank_address"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      {values.country === "United States" && (
  <div className="col-12">
    <Field
      type="text"
      name="ssn_last_4"
      placeholder="Last 4 Digits of SSN"
      className={
        "w-100 form-control" +
        (errors.ssn_last_4 && touched.ssn_last_4 ? " is-invalid" : "")
      }
      value={
        membershipNo
          ? getAuthUser().ssn_last_4
          : values.ssn_last_4
      }
    />
    <ErrorMessage
      name="ssn_last_4"
      component="div"
      className="invalid-feedback"
    />
  </div>
)}
                      <div className="col-12">
                        <Field name="account_no">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              type="text"
                              mask={accountNumberMask}
                              guide={false}
                              // onChange={handleChange}
                              onChange={(e) => {
                                const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                                setFieldValue("account_no", unmaskedValue);
                              }}
                              onBlur={handleBlur}
                              placeholder="Account Number"
                              className={
                                "w-100 form-control" +
                                (errors.account_no && touched.account_no
                                  ? " is-invalid"
                                  : "")
                              }
                              value={
                                membershipNo
                                  ? getAuthUser()?.account_no
                                  : values.account_no
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="account_no"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12">
                        <Field>
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              type="text"
                              name="routing_no"
                              mask={routingNumberMask}
                              guide={false}
                              // onChange={handleChange}
                              onChange={(e) => {
                                const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                                setFieldValue("routing_no", unmaskedValue);
                              }}
                              onBlur={handleBlur}
                              placeholder="Routing Number or Sort Code"
                              className={
                                "w-100 form-control" +
                                (errors.routing_no && touched.routing_no
                                  ? " is-invalid"
                                  : "")
                              }
                              value={
                                membershipNo
                                  ? getAuthUser()?.routing_no
                                  : values.routing_no
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="routing_no"
                          component="div"
                          className="invalid-feedback"
                        />
                      </div>
                      <div className="col-12 card_section">
                        <br />
                        <b>
                          Your Credit Card Information for your payment for
                          registration.
                        </b>
                        <span className="form_heading">Card Type</span>
                        <div className="d-flex align-items-center">
                          <div className="col-3 p-0">
                            <button
                              className="visa_card"
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue("card_details.card_type", "Visa");
                              }}
                            >
                              <img src={Visa} />
                            </button>
                          </div>
                          <div className="col-3 p-0">
                            <button
                              className="master_card"
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue(
                                  "card_details.card_type",
                                  "Master Card"
                                );
                              }}
                            >
                              <img src={MasterCard} />
                            </button>
                          </div>
                          <div className="col-3 p-0">
                            <button
                              className="american_express"
                              onClick={(e) => {
                                e.preventDefault();
                                setFieldValue(
                                  "card_details.card_type",
                                  "American Express"
                                );
                              }}
                            >
                              <img src={Amex} />
                            </button>
                          </div>
                          <div className="col-3 p-0">
                            <span
                              className="form_heading p-0"
                              onClick={() => {
                                setFieldValue("card_details.card_type", "");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Others
                            </span>
                          </div>
                        </div>
                        <input
                          type="text"
                          name="card_details.card_type"
                          // value={values.card_details.card_type}
                          value={
                            membershipNo
                              ? getAuthUser()?.card_details[0]?.card_type
                              : values.card_details?.card_type
                          }
                          onChange={handleChange}
                          placeholder="Card Type"
                          className={
                            "w-100 form-control" +
                            (errors.card_details?.card_type &&
                            touched.card_details?.card_type
                              ? " is-invalid"
                              : "")
                          }
                          disabled={
                            values.card_details.card_type === "Visa" ||
                            values.card_details.card_type ===
                              "American Express" ||
                            values.card_details.card_type === "Master Card"
                          }
                        />
                        <ErrorMessage
                          name="card_details.card_type"
                          component="div"
                          className="invalid-feedback"
                        />
                        <Field name="card_details.card_number">
                          {({ field }) => (
                            <MaskedInput
                              {...field}
                              mask={cardNumberMask}
                              type="text"
                              guide={false}
                              onChange={(e) => {
                                const unmaskedValue = e.target.value.replace(/ /g, ''); // Remove spaces
                                setFieldValue("card_details.card_number", unmaskedValue);
                              }}
                              // onChange={handleChange}
                              onBlur={handleBlur}
                              placeholder="Card Number"
                              className={
                                "w-100 form-control" +
                                (errors.card_number && touched.card_number
                                  ? " is-invalid"
                                  : "")
                              }
                              value={
                                membershipNo
                                  ? getAuthUser()?.card_details[0]?.card_number
                                  : values.card_details[0]?.card_number
                              }
                            />
                          )}
                        </Field>
                        <ErrorMessage
                          name="card_details.card_number"
                          component="div"
                          className="form_invalid_1"
                        />
                        <div className="d-flex" style={{ marginLeft: "-4%" }}>
                          <div>
                            <Field name="card_details.card_expiry">
                              {({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={[
                                    /[0-1]/,
                                    /[0-9]/,
                                    "/",
                                    /[2-9]/,
                                    /[3-9]/,
                                  ]}
                                  type="text"
                                  guide={false}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="MM/YY"
                                  className={
                                    "w-50 form-control ml-3 mr-3 text-center" +
                                    (errors.card_expiry && touched.card_expiry
                                      ? " is-invalid"
                                      : "")
                                  }
                                  value={
                                    membershipNo
                                      ? getAuthUser()?.card_details[0]
                                          ?.card_expiry
                                      : values.card_details[0]?.card_expiry
                                  }
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="card_details.card_expiry"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                          <div>
                            <Field name="card_details.cvv">
                              {({ field }) => (
                                <MaskedInput
                                  {...field}
                                  mask={[/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/]}
                                  type="password"
                                  guide={false}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  placeholder="CVV"
                                  className={
                                    "w-50 form-control ml-3 mr-3 text-center" +
                                    (errors.cvv && touched.cvv
                                      ? " is-invalid"
                                      : "")
                                  }
                                  value={
                                    membershipNo
                                      ? getAuthUser()?.card_details[0]?.cvv
                                      : values.card_details[0]?.cvv
                                  }
                                />
                              )}
                            </Field>
                            <ErrorMessage
                              name="card_details.cvv"
                              component="div"
                              className="form_invalid"
                            />
                          </div>
                        </div>
                        <div className="col-12 membership_fee mt-4">
                          <p>
                            Membership Fee one time on signing:
                            <br />
                            USD 99
                          </p>
                        </div>
                        <div className="notes_box col-12">
                          <p>
                            Note : Annual Membership Renewal Fee : <br />
                            USD 99
                          </p>
                        </div>
                        <div className="col-12 notes_dollars">
                          <p>
                            (It will be charged in US Dollars as well as
                            credited monthly payments to you based on Meals
                            served by you minus 15% Homey-FOODS service fees. Charges
                            and credits will be in USD based on the prevailing
                            exchange rate set by your Credit Card provider)
                          </p>
                        </div>
                      </div>
                    </div>

                    <br />
                    <ScrollToFieldError />
                  </fieldset>
                  <div className="col-12 p-0">
                    {membershipNo ? (
                      <button
                      className="btn w-100"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/mealhost-code-of-Conduct");
                      }}
                    >
                      Next
                    </button>
                    ) : (
                      <div style={{ textAlign: "center" }}>
                        <p>
                          <b>Google Navigation downloaded on mobile phone?</b>
                        </p>
                        <label>
                          <b>Yes</b>
                          <input
                            type="radio"
                            name="google"
                            onChange={() => setShowLink(false)}
                          />
                        </label>
                        <label>
                          <b>No</b>
                          <input
                            type="radio"
                            name="google"
                            defaultChecked
                            onChange={() => setShowLink(true)}
                          />
                        </label>
                        {showLink && (
                          <div>
                            <p>
                              You can download Google Navigation from{" "}
                              <a
                                target="_blank"
                                href="https://play.google.com/store/apps/details?id=com.google.android.apps.maps&hl=en-IN"
                              >
                                here
                              </a>
                              .
                            </p>
                          </div>
                        )}
                        <hr />
                        <button
                          type="submit"
                          value="REGISTER"
                          className="btn w-100"
                        >
                          Click To Pay
                        </button>
                      </div>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div
          className="alreay-account-section centered"
          style={{ paddingBottom: "2rem" }}
        >
          <b>
          <p>
            ALREADY HAVE AN ACCOUNT ? &nbsp;
            <a
              onClick={() => {
                localStorage.clear();
                navigate("/user-login");
              }}
              style={{ cursor: "pointer", color: "white" }}
            >
              LOG IN
            </a>
          </p>
          </b>
        </div>
        </div>
        {/* <div className="alreay-account-section">
          <p>
            ALREADY HAVE AN ACCOUNT ? &nbsp;
            <a
              onClick={() => {
                localStorage.clear();
                navigate("/user-login");
              }}
              style={{ cursor: "pointer", color: "white" }}
            >
              LOG IN
            </a>
          </p>
        </div> */}
      </main>
      <Footer />
    </div>
  );
}
