import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Header } from "../Header/header";
import { Footer } from "../Footer/footer";

export const MealHostInstructions = () => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <div className="max-resolution">
      <section className="title_box">
          <h2>MEAL HOST YOUR INSTRUCTIONS</h2>
      </section>
      <Header/>
      <section className="title_box">
        <div className="container">
          <p>
            Use culinary skills offer regional cuisines and become financially
            successful.{" "}
          </p>
        </div>
      </section>
      <main>
        <div className="container">
          <div className="instructions_box">
            <p>
              1. Suggest offer your unique regional and family heirloom
              cuisines.{" "}
            </p>
            <p>
              2. Offer combo meals : Breakfast ( 8 - 10 AM ) lunch (12 - 3PM )
              Dinner ( 8 - 11 PM). Combo meals minimum should comprise of
              starter, main meal and dessert plus accompanying condiments.
            </p>
            <p>
              3. You have option to offer seven different Meal Combos for
              Breakfast, Lunch and Dinner each. Or you can limit the need to
              prepare multiple meals daily, you can repeat. So, you will need to
              prepare only one Meal Combo each for Breakfast, Lunch Dinner any
              day of the week. Allow you to keep your costs and work down
              instead having to prepare multiple meals each day.
            </p>
            <p>4. For each of Combo Meal select : </p>
            <div className="mt-4">
              <p>
                <strong>Veg / Non-Veg</strong>{" "}
              </p>
              <p>
                <strong>Vegan / Halal / Kosher</strong>
              </p>
              <p>
                <strong>
                  ALERGIES : Eggs / Peanuts / Shell Fish / Soy / Gluten
                </strong>
              </p>
              <p>
                SUGGESTION : ELIMINATE THE USE OF THE COMMON ALLEGERENS LISTED
                ABOVE FROM YOUR MEAL RECIPE, IF YOU MUST USE STATE SO WHICH ONE
                PRESENT IN YOUR MEALS
              </p>
              <p>
                5. Provide Description of Combo Meal along with attractive photo
                for forwarding to Patrons to select your meal, Suggested
                description : - Veg, Thai delicious healthy meal - Upload a
                photo from phone gallery or take a photo on smartphone and upload
              </p>
              <p>
                6. Suggested Combo Meal Prices Breakfast US Dollars 10, Lunch US
                Dollars 15, Dinner US Dollars 20. Though based on your local
                costs and prevailing market conditions, you may wish to lower or
                increase prices.
              </p>
              <p>
                7. Use the resources on our www.HomeFOODS.com for information on
                how to take great photos of meals, prepare for hosting a meal at
                your home as well as free training programs.
              </p>
              <p>8. After serving the meals rate your patrons.</p>
            </div>
            <div className="note_wrong mt-4">
              <p style={{color:"black"}}>
                Become the Best Homey-FOODS Meal Host and get highest stars from
                your Patrons - Grow your business.
              </p>
            </div>
            <div className="meal_host_box">
              {
                 location.state === "instruction" ? <a
                 onClick={(e)=>{
                   e.preventDefault();
                   navigate("/mealhost-home")
                 }}
                 style={{ cursor: "pointer", color: "white" }}
               >
                 Go to Home
               </a>
                :
                location.state === "updatemenu" ? <a
                onClick={(e)=>{
                  e.preventDefault();
                  navigate("/update-menu")
                }}
                style={{ cursor: "pointer", color: "white" }}
              >
                Click Next to Update Your Meals Information
              </a>
              :
              // location.state === "mymenu" ?
              <a
              onClick={(e)=>{
                e.preventDefault();
                navigate("/mealhost-home", {state:"mymenu"})
              }}
              style={{ cursor: "pointer", color: "white" }}
            >
              Click Next to Fill Your Combo Meals Information
            </a>
          //   :
          //    <a
          //    onClick={(e)=>{
          //      e.preventDefault();
          //      navigate("/mealhost-home")
          //    }}
          //    style={{ cursor: "pointer", color: "white" }}
          //  >
          //    Click Next to Fill Your Combo Meals Information
          //  </a>
              }
              
            </div>
          </div>
        </div>
      </main>
      <Footer/>
    </div>
  );
};
